import { Box } from '@mui/material';
import { BaseRow } from '~/components/common';

import Card from './components/Card';
import {
  CalendarIcon,
  TotalEmployeeIcon,
  TotalCustomerIcon,
  TotalEarningIcon,
  TotalSaleIcon,
  OrdersIcon,
  UsersIcon,
} from '~/assets/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~/utils/redux/hooks';

const Dashboard = () => {
  //#region Declare Hook
  const { t } = useTranslation('admin/dashboard');
  //#endregion Declare Hook

  //#region Selector
  // const { totalItems } = useAppSelector((state) => state.dashboard);
  //#endregion Selector

  return (
    <>

    </>
  );
};

export default Dashboard;
