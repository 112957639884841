import classNames from 'classnames/bind';
import styles from './Task.module.scss';

const cx = classNames.bind(styles);

const Task = () => {
  return <>Task Admin Page</>;
};

export default Task;
