import { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '~/thunks/auth/authSlice';
import { wssURL } from '~/utils/constants/env';
import { CODE_CLOSE_WSS_LOGOUT, REASON_CLOSE_WSS_LOGOUT } from '~/utils/constants/websocket';
import { StorageEnum } from '~/utils/enum/base';
import { WebSocketEventEnum } from '~/utils/enum/websocket';
import { login } from '~/utils/helper/websocket';
import { IDataWSSSendMessage, IMessageWSS } from '~/utils/interface/websocket';

type ContextValueWS = {
  wss?: WebSocket | null;
  message?: IMessageWSS<IDataWSSSendMessage>;
  socketId?: string;
};

const WebSocketContext = createContext<ContextValueWS>({});

type WebSocketContextProp = {
  children: ReactNode;
};

const WebSocketProvider = ({ children }: WebSocketContextProp) => {
  const labelLog = '[WebSocketProvider]';

  const accessToken = useSelector(selectAccessToken);

  const wssRef = useRef<WebSocket | null>();
  const [message, setWSData] = useState<IMessageWSS<IDataWSSSendMessage>>();
  const [socketId, setSocketId] = useState<string>();
  const timeoutReconnect = useRef<NodeJS.Timeout>();
  const contextValueWS: ContextValueWS = { wss: wssRef.current, message, socketId };

  useEffect(() => {
    if (wssRef.current) {
      return;
    }
    const accessTokenPersist = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
    if (accessTokenPersist || accessToken) {
      connectWebSocket();
    }
  }, [accessToken]);

  useEffect(() => {
    return () => {
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current);
    };
  }, []);

  const connectWebSocket = () => {
    const labelLogLocal = `${labelLog} [connectWebSocket]`;
    if (!wssURL) {
      return;
    }

    const ws = new WebSocket(wssURL);
    wssRef.current = ws;
    wssRef.current.onopen = () => {
      console.log(`${labelLogLocal} [onopen] webSocket connected`);
      wssRef.current && login(wssRef.current);
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current); // Clear timeout reconnect
    };

    wssRef.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === WebSocketEventEnum.CONNECT_SUCCESS && data.socketId) {
        setSocketId(data.socketId as string);
      }
      setWSData(data);
    };

    wssRef.current.onclose = (e) => {
      if (e.code === CODE_CLOSE_WSS_LOGOUT && e.reason === REASON_CLOSE_WSS_LOGOUT) {
        wssRef.current = null;
        return;
      }
      timeoutReconnect.current = setTimeout(() => {
        console.log('re - connect');
        connectWebSocket();
      }, 1000);
    };

    wssRef.current.onerror = (err) => {
      ws.close();
    };
  };

  return <WebSocketContext.Provider value={contextValueWS}>{children}</WebSocketContext.Provider>;
};

export { WebSocketContext, WebSocketProvider };
