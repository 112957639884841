// EmployeeCard.js
import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import { IEmployee } from '~/utils/interface/employee';
import { ROLE } from '~/utils/constants/employee';

type EmployeeCardProps = {
  employee: IEmployee;
  onClick?: () => void;
};

const EmployeeCard = ({ employee, onClick }: EmployeeCardProps) => {
  const roleName = ROLE.find((role) => role.id === employee.role)?.name || employee.role;

  return (
    <Box onClick={onClick} className="cursor-pointer  bg-white py-[30px] rounded-2xl  flex flex-col items-center border border-gray-200">
      <Avatar src={employee.profile?.avatar?.url} alt={employee.username} className="w-16 h-16 mb-2 " />
      <Typography variant="h6" className=" text-gray-800">
        {employee.profile?.fullName}
      </Typography>
      <Typography  className="text-[#645B55] mb-2 text-[12px]">
        {roleName}
      </Typography>
      <Box
        className={`px-3 py-1 rounded-full font-medium text-[12px] ${
          employee.state ? 'bg-[#B1F5B1] text-[#015E01]' : 'bg-[#FFCECE] text-[#5E0101]'
        }`}
      >
        {employee.state ? 'ONLINE' : 'OFFLINE'}
      </Box>
    </Box>
  );
};

export default EmployeeCard;