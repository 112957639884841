import { Box, Button, Card, CardContent, IconButton, InputLabel, Link, Modal, ModalProps, TextField, Typography } from "@mui/material";
import styles from './Crm.module.scss';
import classNames from 'classnames/bind';
import { CloseIcon } from "~/assets/icons";
import { useFormik } from "formik";
import { IBodySignUp } from "~/utils/interface/auth";
import { useEffect, useState } from "react";
import BaseSelect from "~/components/common/base-select/BaseSelect";
import BaseUploadFile from "~/components/common/base-upload-file/BaseUploadFile";
import BaseRow from "~/components/common/base-row/BaseRow";
import { ABOUT_US, INTERESTED, SALES_REP, SORTTEST } from "~/utils/constants/crm";
import SelectFilter from "../common/select-filter/SelectFilter";
import SelectRadio from "../common/SelectRadio/SelectRadio";
import { images } from '~/assets';
import { UploadFile } from "~/utils/types/common";
import employeeApi from "~/apis/employee";
import { useAppDispatch, useAppSelector } from "~/utils/redux/hooks";
import { handleApiError } from "~/utils/helper";
import { handleEmployeeSales } from "~/thunks/employee/employeeThunk";
import { handleGetProductType } from "~/thunks/product.ts/productThunk";
import { IParamsAddLead } from "~/utils/interface/lead";
import { handleAddLead } from "~/thunks/lead/leadThunk";
import { values } from "lodash";

type CrmAddLeadProps = Omit<ModalProps, 'children'> & {
    open:boolean;
    onCancel: () => void;
    onConfirm: () => void;
};


type IBoddyAddLead = {
    name: string,
    email: string,
    address: string,
    phoneNumber: string,
    referral: string,
    file: any,
    salesRepsIds: string[],
    interestedInIds: string,
}

const initialValues:IBoddyAddLead = {
    name: '',
    email: '',
    address: '',
    phoneNumber: '',
    referral: '',
    file: [],
    salesRepsIds: [],
    interestedInIds: "",
};
  

export const CrmAddLead = (props: CrmAddLeadProps) => {
    const { open, onCancel, onConfirm, ...restProps } = props;

    const dispatch = useAppDispatch();
    
    const [isOpendForm, setOpendForm] = useState(false);
    const [isOpendSuccess, setOpendSuccess] = useState(false);

    const [listSales, setListSales] = useState<{id:string; name:string}[]>([]);
    const [listSalesSelect, setListSalesSelect] = useState<string[]>([]);
    const [listProductType, setListProductType] = useState<{id:string; name:string}[]>([]);

    const { sales } = useAppSelector((state) => state.employee);
    const { productType } = useAppSelector((state) => state.product);

    const cx = classNames.bind(styles);

    const [formError, setFormError] = useState<any>({
        email: '',
        password: '',
        username: ''
      })
    
    const form:any = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
         console.log(values);
        // return;

        const params:IParamsAddLead= {
            name: values.name,
            email: values.email,
            address: values.address,
            phoneNumber: values.phoneNumber,
            referral: values.referral,
            file: values.file,
            salesRepsIds: listSalesSelect,
            interestedInIds: [values.interestedInIds]
        }

        try {
            setOpendForm(false);
            await dispatch(handleAddLead({params:params})).unwrap();
            setOpendSuccess(true);
        } catch (e) {    
            setOpendForm(true);
            handleApiError(e, setFieldError);
        }
    }
    });

    const renderRadioOptionItem = (item:any, isSelect:boolean ,index: number)=>{
        if(item.id == "") return <></>
    return <div className='flex items-center text-[12px]'>
        <div className="w-2 h-2 rounded-[50%] bg-[#6495ED] mr-[6px]"/>
        {item.name}
    </div>
    }

    const onAddLead = () => {
        setOpendForm(false);
        setOpendSuccess(true);
    }

    const onSalesReps = (searchInput: string, searchFields: Array<any>) =>{
        console.log("searchFields", searchFields);
    }

    useEffect(()=>{
        if(open){
            setOpendForm(true);
            setOpendSuccess(false);
        }else{
            setOpendForm(false);
            setOpendSuccess(false);
        }
    },[open])

    useEffect(()=>{
        if(!productType) return;

        let producInfo = [];
        console.log("productType",productType);
        for(let i = 0; i< productType?.length; i++){
            producInfo.push(
            {
                id:productType[i].id,
                name:productType[i].title
            })
        }
        setListProductType(producInfo);
    },[productType])

    useEffect(()=>{
        if(!sales) return;

        let saleInfo = []
        for(let i = 0; i< sales?.length; i++){
            saleInfo.push(
            {
                id:sales[i].id,
                name:sales[i].username
            })
        }
        setListSales(saleInfo);
    },[sales])

    useEffect(() => {
        dispatch(handleEmployeeSales())
          .unwrap()
          .catch((e:any) => {
            handleApiError(e);
          });

        dispatch(handleGetProductType())
          .unwrap()
          .catch((e:any) => {
            handleApiError(e);
          });
    }, [dispatch]);
      
    

    return (
        <>
            <Modal open={isOpendForm}>
                <Box className={cx('modal-container')}>
                    <IconButton className='absolute top-[10px] right-[10px]' onClick={onCancel}>
                        <CloseIcon className='w-[10px] h-[10px]' />
                    </IconButton>

                    <Box className={cx('header-container')}>
                        <Box className={cx('alert-title')}>Add New Lead</Box>
                    </Box>

                    <Box className={cx('content-container','w-full pt-[30px]')}>
                        <Box className="w-full">
                            <Box className={cx("w-full")}>
                                <InputLabel className={cx('!text-[12px] text-[#3E5564] !font-normal !leading-[14.4px] !mb-[10px]')}>
                                    How did they hear about us?
                                </InputLabel>
                                <BaseSelect
                                    placeholder="Select"
                                    name='referral'
                                    value={form.values?.referral}
                                    options={ABOUT_US}
                                    onChange={form.handleChange}
                                    error={Boolean(form.errors?.referral)}
                                    helperText={form.errors?.referral}
                                    isSearchEnable={true}
                                ></BaseSelect>
                            </Box>
                        </Box>

                        <BaseRow className="w-full gap-[20px] flex mt-5">
                            <Box className={cx(`input-label`)}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                    Profile Image
                                </InputLabel>
                                <BaseUploadFile
                                    uploadedFiles={typeof form.values?.file === 'string' ? [{ url: form.values?.file }] : undefined}
                                    onChange={(files: Array<UploadFile>) => {
                                        form.setFieldValue('file', files[0]?.file ?? '');
                                    }}
                                />
                            </Box>

                            <Box className={cx(`input-label`,"w-full")}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                    Name
                                </InputLabel>
                                <TextField
                                    className='w-full'
                                    name='name'
                                    placeholder='Name'
                                    value={form.values.name}
                                    onChange={form.handleChange}
                                    error={Boolean(formError.name)}
                                    helperText={formError.name}
                                />
                            </Box>
                        </BaseRow>

                        <BaseRow className="w-full gap-[20px] mt-5">
                            <Box className={cx(`input-label`, "w-full")}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                Email
                                </InputLabel>
                                <TextField
                                    className='w-full'
                                    name='email'
                                    placeholder='Email'
                                    value={form.values.email}
                                    onChange={form.handleChange}
                                    error={Boolean(formError.email)}
                                    helperText={formError.email}
                                />
                            </Box>

                            <Box className={cx(`input-label`, "w-full")}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                Phone Number
                                </InputLabel>
                                <TextField
                                    className='w-full'
                                    name='phoneNumber'
                                    placeholder='Phone Number'
                                    value={form.values.phoneNumber}
                                    onChange={form.handleChange}
                                    error={Boolean(formError.phoneNumber)}
                                    helperText={formError.phoneNumber}
                                />
                            </Box>
                        </BaseRow>

                        <Box className="w-full mt-5">
                            <Box className={cx(`input-label`)}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                Address
                                </InputLabel>
                                <TextField
                                    className='w-full'
                                    name='address'
                                    placeholder='Address'
                                    value={form.values.address}
                                    onChange={form.handleChange}
                                    error={Boolean(formError.address)}
                                    helperText={formError.address}
                                />
                            </Box>
                        </Box>

                        <BaseRow className="w-full gap-[20px] mt-5">
                            <Box className={cx('w-full')}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                Sales Rep
                                </InputLabel>
                                <SelectRadio
                                    name='searchFields'
                                    options={listSales}
                                    onChange={values=>{
                                        let ids:string[]=[];
                                        values.map((item)=>ids.push(item.id) );
                                        setListSalesSelect(ids);
                                    }}
                                    // search={searchInput}
                                    // onApply={onSalesReps}
                                    renderOptionItem={(item:any, isSelect:boolean ,index: number) => renderRadioOptionItem(item, isSelect ,index) }
                                />
                            </Box>
                           
                            <Box className={cx('w-full')}>
                                <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                                Interested In
                                </InputLabel>
                                <BaseSelect
                                    name='interestedInIds'
                                    value={form.values?.interestedInIds}
                                    options={listProductType}
                                    onChange={form.handleChange}
                                    placeholder="Select"
                                    error={Boolean(form.errors?.interestedInIds)}
                                    helperText={form.errors?.interestedInIds}
                                ></BaseSelect>
                            </Box>
                        </BaseRow>

                        <Box className="w-full flex justify-end mt-10">
                            <Button className={cx('button-transparent','mr-3')} onClick={onCancel}>Cancel</Button>
                            <Button className={cx('button-blue')} onClick={form.handleSubmit}>Save</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            <Modal open={isOpendSuccess}>
                <Box className={cx('modal-dialog','modal-container')}>
                    <IconButton className='absolute top-[10px] right-[10px]' onClick={()=>{onConfirm()}}>
                        <CloseIcon className='w-[10px] h-[10px]' />
                    </IconButton>
                    <Box className={cx('gap-5 text-center')}>            
                        <img src={images.star_complate} alt='' className={cx('m-auto','w-[120px] h-[120px]')}  /> 
                    </Box>
                    <Box className={cx('mt-8 text-center')}>
                        <Typography className={cx('!font-medium !text-[#0E1E29] !text-[24px] !leading-[34px]')} variant='h2'>
                        The lead has been created! What would you like to do next?
                        </Typography>
                    </Box>

                    <Box className={cx('mt-5 text-center')}>
                        <Button
                        type='submit'
                        variant='contained'
                        //color='primary'
                        sx={{
                            background:'#39A6F5',
                            boxShadow: 'none',
                            '&:hover': {
                            boxShadow: 'none',
                            },
                        }}
                        className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
                        >
                        Schedule an Appointment
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
