import ForgetPass from "~/pages/admin/forgetPass/ForgetPass";

// Auth
export const urlRefreshToken = 'auth/refresh-token';
export const urlApiAuth = {
  login: 'api/auth/login',
  signup: 'api/auth/signup',
  forgetPass: 'api/auth/forgot-password',
  resetPass: 'api/auth/reset-password',
  info: 'api/auth/me',
  signupWithInvite: 'api/auth/signup-with-invite',
  refreshToken: 'api/auth/refresh-token',
};