import classNames from 'classnames/bind';
import styles from './Schedule.module.scss';

const cx = classNames.bind(styles);

const Schedule = () => {
  return <>Schedule Admin Page</>;
};

export default Schedule;
