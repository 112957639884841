import { createAsyncThunk } from '@reduxjs/toolkit';
import authApi from '~/apis/auth';

import { FORGET_PASSWORD, GET_PROFILE, LOGIN, REGISTER, RESET_PASSWORD, REGISTER_WITH_INVITE } from '~/utils/constants/actionType';
import { IBodyForgetpass, IBodyLogin, IBodyResetPass, IBodySignUp, IBodyInviteSignUp } from '~/utils/interface/auth';

export const handleLogin = createAsyncThunk(
  LOGIN,
  async (
    payload: {
      body: IBodyLogin;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await authApi.login(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleForgetPass = createAsyncThunk(
  FORGET_PASSWORD,
  async (
    payload: {
      body: IBodyForgetpass; },
      { rejectWithValue }
    ) => {
      try {
        const resp = await authApi.forgetPass(payload);
        return resp.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }); 
  
export const handleRegester = createAsyncThunk(
  REGISTER,
  async (
    payload: {
      body: IBodySignUp;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await authApi.signup(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleRegesterWithInvite = createAsyncThunk(
  REGISTER_WITH_INVITE,
  async (
    payload: {
      body: IBodyInviteSignUp;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await authApi.signupWithInvite(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleResetPass = createAsyncThunk(
  RESET_PASSWORD,
  async (
    payload: {
      body: IBodyResetPass;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await authApi.resetPass(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProfile = createAsyncThunk(GET_PROFILE, async (payload: string, { rejectWithValue }) => {
  try {
    // const resp = await authApi.getProfile(payload);
    // return resp.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
