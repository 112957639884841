import classNames from 'classnames/bind';
import styles from './Reports.module.scss';

const cx = classNames.bind(styles);

const Reports = () => {
  return <>Reports Admin Page</>;
};

export default Reports;
