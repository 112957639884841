import classNames from 'classnames/bind';
import styles from './Inventory.module.scss';

const cx = classNames.bind(styles);

const Inventory = () => {
  return <>Inventory Admin Page</>;
};

export default Inventory;
