import { AxiosError } from "axios";
import { toast } from 'react-toastify';

export function notify(type: string, message: string) {
    let option = { toastId: 'bor-toast' };
    if (type === 'error') {
      return toast.error(message, option);
    }
  
    if (type === 'success') {
      return toast.success(message, option);
    }
  
    if (type === 'warning') {
      return toast.warning(message, option);
    }
  
    return toast(message, option);
  }

export function catchError(e: unknown, handleError: (errorMessage: string) => void) {
    if (typeof e === 'string') {
      handleError(e);
    } else if (e instanceof AxiosError) {
      handleError(e?.response?.data?.message);
    } else if (e instanceof Error) {
      handleError(e.message);
    }
  }
  
export function handleApiError(
    e: unknown,
    setFieldError?: (field: string, message: string | undefined) => void,
    isNotify: boolean = true
  ) {
    if (isNotify) {
      catchError(e, (message) => {
        notify('error', message || 'Something went wrong.');
      });
    }
  
    if (setFieldError && e instanceof AxiosError && e?.response?.data?.data) {
      for (let data of e.response.data.data) {
        setFieldError(data.field, data.message);
      }
    }

    if (setFieldError && e instanceof AxiosError && e?.response?.data?.message) {
        notify('error', e.response.data.message);
      }
  }
