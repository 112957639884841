import { useState, useEffect } from 'react';

const useDebounce = <T extends {}>(value: T, timeout: number = 200): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value); // Update debounced value after timeout
    }, timeout);

    // Cleanup function to clear timeout
    return () => {
      clearTimeout(handler);
    };
  }, [value, timeout]);

  return debouncedValue;
};

export default useDebounce;
