import adminLogoSidebar from '~/assets/images/admin_logo_sidebar.svg';
import logo from '~/assets/images/logo.png';
import logoPng from '~/assets/images/logo.png';
import defaultImage from '~/assets/images/user_default.png';
import avatar_deafault from '~/assets/images/avatar_deafault.png';
import bgLogo from '~/assets/images/bgLogoLogin.png';
import checkbox_none from '~/assets/images/icon/checkbox_none.svg';
import hidePass from '~/assets/images/icon/hide-pass.svg';
import showPass from '~/assets/images/icon/show-pass.png';
import social_google from '~/assets/images/icon/social/google.svg';
import social_facebook from '~/assets/images/icon/social/facebook.svg';
import social_apple from '~/assets/images/icon/social/apple.svg';
import star_complate from '~/assets/icons/star-complate.svg';
import emailIcon from '~/assets/icons/user-detail/email.svg';
import blood from '~/assets/icons/user-detail/blood.svg';
import city from '~/assets/icons/user-detail/city.svg';
import dateJoin from '~/assets/icons/user-detail/date-join.svg';
import department from '~/assets/icons/user-detail/department.svg';
import dislikes from '~/assets/icons/user-detail/dislike.svg';
import hobbies from '~/assets/icons/user-detail/hobbies.svg';
import likes from '~/assets/icons/user-detail/likes.svg';
import father from '~/assets/icons/user-detail/father-mother-name.svg';
import religion from '~/assets/icons/user-detail/religion.svg';
import state from '~/assets/icons/user-detail/state.svg';
import tips from '~/assets/icons/user-detail/tips.svg';
import star from '~/assets/icons/user-detail/star.svg';
import production from '~/assets/icons/user-detail/production.svg';
import sale from '~/assets/icons/user-detail/sale.svg';
import productionValue from '~/assets/icons/user-detail/production-value.svg';
import phone from '~/assets/icons/user-detail/phone.svg';
import jobCompleted from '~/assets/icons/user-detail/job-completed.svg';
import hoursWorked from '~/assets/icons/user-detail/hours-worked.svg';
import bonuses from '~/assets/icons/user-detail/bonuses.svg';
import address from '~/assets/icons/user-detail/address.svg';
import role from '~/assets/icons/user-detail/role.svg';

const images = {
  logo,
  logoPng,
  adminLogoSidebar,
  adminBackgroundImageLogin: require('./admin_background_image_login.png'),
  defaultImage,
  avatar_deafault,
  checkbox_none,
  hidePass,
  showPass,
  star_complate,
  social:{
    social_google,
    social_facebook,
    social_apple
  },
  userDetail: {
    emailIcon,
    blood,
    city,
    dateJoin,
    department,
    dislikes,
    hobbies,
    likes,
    father,
    religion,
    state,
    tips,
    star,
    production,
    sale,
    productionValue,
    phone,
    jobCompleted,
    hoursWorked,
    bonuses,
    address,
    role
  }
};

export default images;
