import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import authReducer from '~/thunks/auth/authSlice';
import employeeReducer from '~/thunks/employee/employeeSlice';
import leadReducer from '~/thunks/lead/leadSlice';
import productReducer from '~/thunks/product.ts/productSlice';


const rootReducer = combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  lead: leadReducer,
  product: productReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;