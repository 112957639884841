import classNames from 'classnames/bind';
import styles from './Crm.module.scss';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { FilterIcon, MenuVerticalIcon, PhoneIcon, PlusIcon, SortIcon,MailIcon, LocaltionIcon, NoteIcon, CloseIcon } from '~/assets/icons';
import styleColor from '~/assets/icons/icon.module.scss';
import { useEffect, useState } from 'react';
import { CrmAddLead } from '~/components/crm/CrmAddLead';
import BaseImage from '~/components/common/base-image/BaseImage';
import { images } from '~/assets';
import { handleApiError } from '~/utils/helper';
import { handleGetLeads } from '~/thunks/lead/leadThunk';
import { useAppDispatch, useAppSelector } from '~/utils/redux/hooks';
import { ILeadList } from '~/utils/interface/lead';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);
const cxColor = classNames.bind(styleColor);

const Crm = () => {
  const { t } = useTranslation('admin/crm');

  const [isOpendAddLead, setOpendAddLead] = useState(false);
  const [isFinish, setFinish] = useState(false);

  const dispatch = useAppDispatch();
  const { leads, currentPage, totalPage } = useAppSelector((state) => state.lead);
  

  useEffect(() => {
    dispatch(handleGetLeads({}))
      .unwrap()
      .catch((e:any) => {
        handleApiError(e);
      });
  }, [dispatch]);

  return <>
    <Box className="bg-[#ffffff] w-full rounded-[8px] p-[24px]">
      <Box className="flex items-center justify-between mb-5">
        <Box>{t('tilte-dashboard')}
        </Box>
        <Box>
          <Button className='p-[10px] mr-[10px] rounded-[8px] border border-solid text-[12px] leading-[14.5px] border-[#E8EDF1] bg-[#F6F8FA] hover:bg-[#F6F8FA] text-[#3E5564]'> 
            <SortIcon className='w-[12px] h-[12px] mr-[6px]' /> {t('sortBy')}
          </Button>
          <Button className='p-[10px] mr-[10px] rounded-[8px] border border-solid text-[12px] leading-[14.5px] border-[#E8EDF1] bg-[#F6F8FA] hover:bg-[#F6F8FA] text-[#3E5564]'> 
            <FilterIcon className='w-[12px] h-[12px] mr-[6px]' /> {t('filter')}
          </Button>
          
          <Button className='p-[10px] mr-[10px] rounded-[8px] border border-solid text-[12px] leading-[14.5px] border-[#39A6F5] bg-transparent hover:bg-transparent text-[#39A6F5]'
            onClick={()=>{setOpendAddLead(true)}}
          > 
            <PlusIcon className={cxColor('w-[12px] h-[12px] mr-[6px]','icon-fill-39A6F5')} /> {t('add-lead')}
          </Button>
          <Button className='p-[10px] rounded-[8px] border border-solid text-[12px] leading-[14.5px] border-[#39A6F5] bg-[#39A6F5] hover:bg-[#39A6F5] text-[#FFFFFF]'> 
            <PlusIcon className={cxColor('w-[12px] h-[12px] mr-[6px]','icon-fill-ffffff')} /> {t('add-service')}
          </Button>
        </Box>
      </Box>

      <Box className="grid gap-x-8 gap-y-4 grid-cols-4">
        {
          leads.map((item:any)=>{
            let customer:ILeadList = item.customer;
            return (        <Box className="rounded-[8px] border-[#E8EDF1] border p-4">
              <Box className="flex items-center justify-between py-4 px-0 border-b-[#E8EDF1] border-b">
                <Box className="flex items-center">
                  <Box className="mr-2">
                    <BaseImage src={images.avatar_deafault} className={cx('image','w-[30px] h-[30px]  rounded-[50%]')} />
                   
                  </Box>
    
                  <Box >
                    <p className="text-[#0E1E29] text-[14px] leading-[17px] mb-[2px]"> {customer.name}</p>
                    <p className="text-[#8A97A0] text-[10px] leading-[12px]"> Client</p>
                  </Box>
                </Box>
                <Box>
                  <Box className="bg-[#F6F8FA] w-[24px] h-[24px] rounded-[50%] flex items-center justify-center"> <MenuVerticalIcon className='w-[16px] h-[16px]' /></Box>
                </Box>
              </Box>
              <Box className="mt-5 mb-5">
                <ul>
                  <li className="flex items-center mb-4 text-[#3E5564] text-[12px] leading-[14.5px]"> <PhoneIcon className={cxColor('w-[12px] h-[12px] mr-[6px]','icon-fill-3E5564')} /> {customer.phoneNumber}</li>
                  <li className="flex items-center mb-4 text-[#3E5564] text-[12px] leading-[14.5px]"> <MailIcon className={cxColor('w-[12px] h-[12px] mr-[6px]','icon-fill-3E5564')} /> {customer.email}</li>
                  <li className="flex items-center text-[#3E5564] text-[12px] leading-[14.5px]"> <LocaltionIcon className={cxColor('w-[12px] h-[12px] mr-[6px]','icon-fill-3E5564')} />{customer.address}</li>
                </ul>
              </Box>
              <Box className="flex items-center">
                <Box className="bg-[#F6F8FA] w-[32px] h-[32px] rounded-[50%] flex items-center justify-center mr-3"> <MailIcon className={cxColor('w-[16px] h-[16px]','icon-fill-3E5564')} /></Box>
                <Box className="bg-[#F6F8FA] w-[32px] h-[32px] rounded-[50%] flex items-center justify-center mr-3"> <PhoneIcon className={cxColor('w-[16px] h-[16px]','icon-fill-3E5564')} /></Box>
                <Box className="bg-[#F6F8FA] w-[32px] h-[32px] rounded-[50%] flex items-center justify-center"> <NoteIcon className={cxColor('w-[16px] h-[16px]','icon-fill-3E5564')} /></Box>
              </Box>
            </Box>
            )
          })
        }
      </Box>
    </Box>
    <CrmAddLead open={isOpendAddLead} onCancel={()=>{setOpendAddLead(false)}} onConfirm={()=>{setOpendAddLead(false); setFinish(true)}} />



  </>;
};

export default Crm;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

