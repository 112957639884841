//#region Auth
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const GET_PROFILE = 'GET_PROFILE';
export const REGISTER_WITH_INVITE = 'REGISTER_WITH_INVITE';
//#endregion Auth

// Employee
export const GET_SELECT_EMPLOYEES = 'GET_SELECT_EMPLOYEES';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const EMPLOYEE_SALES = 'EMPLOYEE_SALES';

// Lead
export const GET_SELECT_LEADS = 'GET_SELECT_LEADS';
export const CREATE_LEAD = 'CREATE_LEAD';
export const GET_LEADS = 'GET_LEADS';
export const GET_LEAD = 'GET_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const ADD_LEAD = 'ADD_LEAD';


//Product

export const GET_PRODUCT_TYPE = 'GET_PRODUCT_TYPE';