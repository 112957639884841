import { THttpResponse } from '~/utils/types/common';

export const httpResponse: THttpResponse = {};

// Array number HttpResponse don't show toast
export const skipHttpResponseArray: number[] = [10000];

export const httpStatusCode = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};
