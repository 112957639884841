// import { NotFoundPage } from '~/components';
import {
  HomeUser,
} from '~/pages';

import { IRouteModel } from '../interface/common';

// Router && Path

export const userRoute = {
  base: '/',
  home: '/home',
  component: '/component',
};

//#region Patient Routes
export const publicUserRoutes: IRouteModel[] = [
  // {
  //   path: '*',
  //   component: NotFoundPage,
  // },
];

export const privateUserRoutes: IRouteModel[] = [
  {
    path: userRoute.home,
    component: HomeUser,
  },
];
//#endregion Patient Routes

export const publicRoutes = [...publicUserRoutes];


