import { createAsyncThunk } from '@reduxjs/toolkit';
import leadApi from '~/apis/lead';
import {
  CREATE_LEAD,
  UPDATE_LEAD,
  GET_LEAD,
  GET_LEADS,
  GET_SELECT_LEADS,
  ADD_LEAD,
} from '~/utils/constants/actionType';
import {
  IParamsGetLeads,
  IResponseGetSelectLeads,
  IResponseGetLeads,
  IResponseGetLead,
  IParamsAddLead,
} from '../../utils/interface/lead';
import { IResponseLead, ILead } from '../../utils/interface/lead';

export const handleGetSelectLeads = createAsyncThunk<IResponseGetSelectLeads>(
  GET_SELECT_LEADS,
  async (_params, { rejectWithValue }) => {
    try {
      const res = await leadApi.getSelectLeads();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleCreateLead = createAsyncThunk<IResponseLead, ILead>(
  CREATE_LEAD,
  async (data: ILead, { rejectWithValue }) => {
    try {
      const res = await leadApi.createLead(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const handleGetLeads = createAsyncThunk<IResponseGetLeads, IParamsGetLeads>(
  GET_LEADS,
  async (params: IParamsGetLeads = {}, { rejectWithValue }) => {
    try {
      const res = await leadApi.getLeads(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const handleGetLead = createAsyncThunk<IResponseGetLead, string>(
  GET_LEAD,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await leadApi.getLead(id);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleEditLead = createAsyncThunk<IResponseLead, { id: string; params: ILead }>(
  UPDATE_LEAD,
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const res = await leadApi.updateLead(id, params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const handleAddLead = createAsyncThunk<IResponseLead, {params:any}>(
  ADD_LEAD,
  async ({ params }, { rejectWithValue }) => {
    try {
      const res = await leadApi.addLead(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
