export enum EnvironmentEnum {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAG = 'staging',
  PROD = 'production',
}

export enum StorageEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  TIME_TOKEN = 'time_token',
  USER = 'user',
  STATUS_LOGIN = 'statusLogin',
  ACCOUNT_ID = 'accountId',
  DEVICE_ID = 'deviceId',
  SAVE_LOGIN = 'save_login',
}

export enum LanguageEnum {
  EN = 'en',
}

export enum ButtonTypeEnum {
  PRIMARY = 'primary',
  DANGER = 'danger',
}

export enum ResponseStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum MethodRequestServerEnum {
  GET = 'get',
}
