export const emailInputErrors:string[]=[
    `"email" must be a valid email.`,
    `"email" length must be less than or equal to 255 characters long.`,
    ` "email" is not allowed to be empty`,
    `Email already exists`,
    `Account Not Found`
]
export const usernameInputErrors:string[]=[
`"username" length must be less than or equal to 255 characters long`,
`"username" is not allowed to be empty`
]

export const passwordInputErrors:string[]=[
  `"password" length must be less than or equal to 255 characters long`,
  `"password" length must be at least 8 characters long`,
  `"password" is not allowed to be empty`,
]

export const confirmPasswordInputErrors:string[]=[
  `"confirmPassword" length must be less than or equal to 255 characters long`,
  `"confirmPassword" length must be at least 8 characters long`,
  `"confirmPassword" is not allowed to be empty`,
  'The data you entered is not correct. Please review it'
]

const _keyInfos:any = {
  email:{
    key : `"email"`,
    value: `Email`
  },
  username:{
    key : `"username"`,
    value: `Name`
  },
  password:{
    key : `"password"`,
    value: `Password`
  },
  confirmPassword:{
    key : `"confirmPassword"`,
    value: `Confirm Password`
  }
}


const getKeyError = (mess:string, keyDefault:string) => {
    for(let i  =0; i < usernameInputErrors.length; i++){
      if(mess.indexOf(usernameInputErrors[i])!= -1) return "username"
    }

    for(let i  =0; i < passwordInputErrors.length; i++){
      if(mess.indexOf(passwordInputErrors[i])!= -1) return "password"
    }

    for(let i  =0; i < confirmPasswordInputErrors.length; i++){
      if(mess.indexOf(confirmPasswordInputErrors[i])!= -1) return "confirmPassword"
    }

    for(let i  =0; i < emailInputErrors.length; i++){
      if(mess.indexOf(emailInputErrors[i])!= -1) return "email"
    }

    return keyDefault;
}
const getNameKey = (errorKey:string) => {
  let keyInfo = _keyInfos[errorKey];
  return keyInfo ? keyInfo : {
    key : "",
    value: ""
  };
}

const replaceError = (errorKey:string, mess:string) =>{
  let keyInfo = getNameKey(errorKey);
  return mess.replaceAll(keyInfo.key,keyInfo.value)
}

export const checkInputError = (error: any, message:string, keyDefault:string = "email") => {
    if(message.indexOf("Schema invalid:") != -1){
        let messageError = message.split(":")[1];
        let messErrors = messageError.split(".");

        for(let i = 0 ; i < messErrors.length; i++){
          let messI = messErrors[i];
          let key:string = getKeyError(messI,keyDefault);

          error[key] = replaceError(key, messI);
        }

        return [true, error];
    }


    let key:string = getKeyError(message, "");
    if(!key)  return [false, error];

    error[key] = message;
    return [true, error];
}