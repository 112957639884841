import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './SelectFilter.module.scss';
import classNames from 'classnames/bind';
import { FilterIcon, SearchIcon } from '~/assets/icons';
import BaseSelect from '~/components/common/base-select/BaseSelect';
import { SelectOption } from '~/utils/types/common';
import { useState, memo } from 'react';

export type SelectFilterProps = {
  name?: string;
  searchFields: Array<string>;
  search?: string;
  onApply?: (searchInput: string, searchFields: Array<string>) => void;
  parentName?: string;
};

const cx = classNames.bind(styles);

const SelectFilter = (props: SelectFilterProps) => {
  //#region Destructuring Props
  const { parentName } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  const { t } = useTranslation([`admin/${parentName}`, 'admin/common']);
  //#endregion Selector

  //#region Declare State
  const options: Array<SelectOption> = props.searchFields.map((value) => {
    return {
      id: value,
      name: t(`properties.${value}`),
    };
  });
  const [searchFields, setSearchFields] = useState([]);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<boolean>();
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderSelectedOptionComponent = (_item: SelectOption) => {
    return (
      <Box className={cx('select-filter-container')}>
        <Box className={cx('event-select-filter-container')}>
          <Box className={cx('event-select-filter')}>
            <FilterIcon className='w-[13px] h-[13px]' />
            <Box className='ml-[5px]'>{t('filter', { ns: 'admin/common' })}</Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderBottomComponent = () => {
    return (
      <Box className={cx('button')}>
        <Button className={cx('button-text')} onClick={handleValueChange}>
          {t('applyFilter', { ns: 'admin/common' })}
        </Button>
      </Box>
    );
  };

  const renderTopComponent = () => {
    return (
      <Box>
        <TextField
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={t('searchPlaceholder')}
          className='mb-[16px]'
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          InputProps={{ startAdornment: <SearchIcon className='w-[16px]' /> }}
        />
        <Typography className='mb-[16px] text-[14px]/[16.8px] text-[#3A3F51] font-medium'>
          {t('filter', { ns: 'admin/common' })}
        </Typography>
      </Box>
    );
  };

  const handleChange = (item: any) => {
    const value = item.target.value;
    setSearchFields(value);
  };

  const handleValueChange = () => {
    setActive(!!searchFields.length);

    if (props?.onApply) {
      props?.onApply(inputSearch, searchFields);
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#endregion Handle Function

  return (
    <>
      <BaseSelect
        onChange={(item) => handleChange(item)}
        value={searchFields}
        className={cx({ 'active-select': active }, 'bg-white rounded-[8px] !h-[40px]')}
        menuClassName={cx('select-filter-menu')}
        options={options}
        isHideIcon={true}
        multiple
        renderSelected={(item: SelectOption) => renderSelectedOptionComponent(item)}
        bottomComponent={() => renderBottomComponent()}
        topComponent={() => renderTopComponent()}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </>
  );
};

export default memo(SelectFilter);
