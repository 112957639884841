import * as React from 'react';
import styles from './DrawerEmployee.module.scss';
import classNames from 'classnames/bind';
import { CloseIcon, CalendarIcon } from '~/assets/icons';
import { useTranslation } from 'react-i18next';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import { useNavigate } from 'react-router-dom';
import { IEmployee } from '~/utils/interface/employee';
import { BaseRow, BaseSelect, BaseUploadFile, BaseDatePicker } from '~/components/common';
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Drawer,
  Divider,
  InputAdornment,
} from '@mui/material';
import { ROLE } from '~/utils/constants/employee';
import { IEmployeeInvite } from '~/utils/interface/employee';
import { PlusOrangeIcon, SearchIcon, TrashIcon } from '~/assets/icons';
import styleColor from '~/assets/icons/icon.module.scss';
import { invite } from '~/thunks/employee/employeeThunk';
import { useAppDispatch, useAppSelector } from '~/utils/redux/hooks';
import { handleApiError, notify , cast} from '~/utils/helper';

const cxColor = classNames.bind(styleColor);

type DrawerComponentProps = {
  open: boolean;
  data?: IEmployee;
  setOpen: (open: boolean) => void;
  setOpenEdit: (open: boolean) => void;
};

const cx = classNames.bind(styles);
const DrawerComponent: React.FC<DrawerComponentProps> = ({ open, setOpen, data, setOpenEdit }) => {
  const { t } = useTranslation(['admin/account']);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState<IEmployeeInvite[]>([{ email: '', permission: '' }]);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = React.useState<{ email?: string; permission?: string }[]>([]);

  const handleSetOpen = async (open: boolean) => {
    if (!open) {
      setRows([{ email: '', permission: '' }]);
      setErrors([]);
    }
    setOpen(open);
  };
  const handleSetEdit = async (open: boolean) => {
    setOpenEdit(open);
  };

  const handleAddMore = () => {
    setRows([...rows, { email: '', permission: '' }]);
  };

  const handleInputChange = (index: number, field: keyof IEmployeeInvite, value: string) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleSendInvites = async () => {
    if (!validateRows()) {
      return;
    }

    try {
      const data = { employees: rows };
      await dispatch(invite(data)).unwrap();
      notify('success', t('inviteSuccess', { ns: 'admin/common' }));
      handleSetOpen(false);
      setRows([{ email: '', permission: '' }]);
      setErrors([]);
    } catch (e: unknown) {
      handleApiError(e);
    }
  };

  const validateRows = () => {
    const newErrors = rows.map(row => {
      const error: { email?: string; permission?: string } = {};
      if (!row.email) {
        error.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(row.email)) {
        error.email = 'Email is invalid';
      }
      if (!row.permission) {
        error.permission = 'Permission is required';
      }
      return error;
    });
    setErrors(newErrors);
    return newErrors.every(error => Object.keys(error).length === 0);
  };

  return (
    <Drawer anchor='right' open={open} onClose={() => handleSetOpen(false)} className={cx('drawer')}>
      <Box className={`${cx('arrowRight')}`} onClick={() => handleSetOpen(false)}>
        <CloseIcon className={cx('arrowRightDoubleIcon')} />
      </Box>
      <Box role='presentation' className={cx('drawer-container')}>
        <h2 className='text-[24px] pl-[30px] pt-[20px] ' >{t('inviteToAddEmployee')} </h2>

        <Divider className='mt-5 mb-5' />
        <Box className='p-[30px]'>
          {rows.map((row, index) => (
            <BaseRow key={index} className='gap-[20px] mb-[20px]'>
              <Box className='w-2/3'>
                <InputLabel>{t('email')}</InputLabel>
                <TextField
                  name='email'
                  placeholder={t('email')}
                  value={row.email}
                  onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  helperText={errors[index]?.email}
                  error={Boolean(errors[index]?.email)}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '15px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className='w-1/3'>
                <InputLabel>{t('User Permissions')}</InputLabel>
                <BaseSelect
                  name='permission'
                  placeholder={t('User Permissions')}
                  value={row.permission}
                  options={ROLE}
                  onChange={(e) => handleInputChange(index, 'permission', e.target.value)}
                  helperText={errors[index]?.permission}
                  error={Boolean(errors[index]?.permission)}
                />
              </Box>
            </BaseRow>
          ))}

          <Button
            onClick={handleAddMore}
            className="text-[#FD550C] font-bold"
            startIcon={<PlusOrangeIcon className={cxColor('w-[15px] h-[15px]')} />}
          >
            Add More
          </Button>
        </Box>
      </Box>

      <Box display="flex" className="mt-[auto] justify-end gap-2 mb-5 pr-2">
        <Button
          variant='outlined'
          onClick={() => handleSetOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSendInvites}
        >
          Send Invites
        </Button>
      </Box>
    </Drawer>
  );
};

export default React.memo(DrawerComponent);