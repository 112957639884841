import axiosClient from './axiosClient';
import { IApiBaseResponse } from '~/utils/interface/common';
import {
  IParamsGetEmployees,
  IPayloadDeleteEmployee,
  IResponseGetEmployee,
  IResponseGetEmployees,
  IResponseGetSelectEmployees,
  IEmployeeInviteList
} from '~/utils/interface/employee';
import { IResponseEmployee, IEmployee } from '~/utils/interface/employee';

const employeeApi = {
  getSelectEmployees() {
    return axiosClient.get<IApiBaseResponse<IResponseGetSelectEmployees>>('api/employees/selection');
  },

  createEmployee(data: IEmployee) {
    return axiosClient.post<IApiBaseResponse<IResponseEmployee>>('api/employees/new', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getEmployees(params: IParamsGetEmployees) {
    const url = `api/employees`;
    return axiosClient.get<IApiBaseResponse<IResponseGetEmployees>>(url, { params });
  },
  getEmployee(id: string) {
    return axiosClient.get<IApiBaseResponse<IResponseGetEmployee>>(`api/employees/${id}`);
  },
  updateEmployee(id: string, data: IEmployee) {
    return axiosClient.put<IApiBaseResponse<IResponseEmployee>>(`api/employees/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteEmployee(data: IPayloadDeleteEmployee) {
    const url = `employees`;
    return axiosClient.delete<IApiBaseResponse<null>>(url, { data });
  },

  getEmployeeSales() {
    return axiosClient.get<IApiBaseResponse<IResponseGetEmployee>>(`employees/sales/list`);
  },

  inviteEmployees: (data:IEmployeeInviteList) => {
    return axiosClient.post<IApiBaseResponse<any>>(`/api/employees/invite`, data);

  }
};

export default employeeApi;
