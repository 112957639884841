import { Box, Button, Checkbox, css, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material';
import styles from '../Auth.module.scss';
import classNames from 'classnames/bind';
import authApi, { ILoginPayload } from '~/apis/auth';
import { images } from '~/assets';
import { useFormik } from 'formik';
import { useAppDispatch } from '~/utils/redux/hooks';
import { handleLogin } from '~/thunks/auth/authThunk';
import { IBodyLogin } from '~/utils/interface/auth';
import { useNavigate } from 'react-router-dom';
import { handleApiError } from '~/utils/helper/common';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import AdminLogin from '~/layouts/admin/login/AdminLogin';
import { useState } from 'react';
import { checkInputError } from '~/utils/helper/auth';
import { AxiosError } from 'axios';
import { StorageEnum } from '~/utils/enum/base';
import { authActions } from '~/thunks/auth/authSlice';

const cx = classNames.bind(styles);

type Props = {};


const SignInComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const save_login =  localStorage.getItem(StorageEnum.SAVE_LOGIN);

  const save_login_obj = save_login ? JSON.parse(save_login) : {} ;
  const isSavepass:boolean = save_login && save_login_obj.isSave
  const [savePass, setSavepass] = useState(isSavepass);
  const [isLogin, setIsLogin] = useState(false);

  const initialValues: ILoginPayload = {
    email: save_login ? save_login_obj.email : "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  
  const [formError, setFormError] = useState<IBodyLogin>({
    email: '',
    password: '',
  })

  const form:any = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      try {
        setIsLogin(true);
        if(savePass){
          localStorage.setItem(StorageEnum.SAVE_LOGIN, JSON.stringify({
            email: values.email,
            isSave: true
          }))
        }
        else{
          localStorage.setItem(StorageEnum.SAVE_LOGIN, JSON.stringify({
            email: '',
            isSave: false
          }))
        }

        let valueLogin:IBodyLogin ={
          email: values.email,
          password: values.password
        }
        await dispatch(handleLogin({body:valueLogin})).unwrap();
        try {
          let info = await authApi.authMe();
          if(!info.data.data)
          {
            return;
          }
          dispatch(authActions.setUserProfile(info.data.data));
        } catch (error) {
          
        }
        navigate(`${adminRouteAbsolute.dashboard}`);
      } catch (e) {
        setIsLogin(false);
        if(e instanceof AxiosError && e?.response?.data?.message)
          {
            let message:string = e?.response?.data?.message;
            let [isError, error] = checkInputError({
              email: '',
              password: '',
            },message );
            
            if(isError){
              return setFormError(error);
            }
          }
        handleApiError(e, setFieldError);
      }
    }
  });

  const onSavePass = (value:boolean) =>{
    if(!isLogin) setSavepass(value);
    else{
      if(value){
        localStorage.setItem(StorageEnum.SAVE_LOGIN, JSON.stringify({
          email: form.values.email,
          isSave: true
        }))
      }
      else{
        localStorage.setItem(StorageEnum.SAVE_LOGIN, JSON.stringify({
          email: '',
          isSave: false
        }))
      }
    }
  }

  return <div id='signInComponent'>
    <AdminLogin>
      <Box className={cx('relative z-10 ')}>
        <Box component='section' className={cx('w-full h-full')}>
          <Box component='form' onSubmit={form.handleSubmit} className={cx('max-w-[500px] w-full px-4 sm:px-0')}>
            <Box>
              <Box className={cx('gap-5')}>
                
                <Box className={cx('mt-5')}>
                  <Typography className={cx('!font-medium !text-black !text-[36px] !leading-[43.2px]  font-medium text-center text-[#200E01]')} variant='h2'>
                    Hi, Welcome Back! 
                  </Typography>
                  <Typography
                    variant='body1'
                    className={cx('!font-normal text-[16px] !leading-[19.2px] !text-[#3E5564] !mt-2.5 text-center text-[#554A42]')}
                  >
                    Please enter your email and password for sign in
                  </Typography>
                </Box>

              </Box>
              <Box className={cx('sm:mt-[30px] mt-6')}>
                <Box className={cx(`input-label`)}>
                  <InputLabel className={cx('!text-[12px] text-[#2F1401] !font-normal !leading-[14.4px]')}>
                    Email
                  </InputLabel>
                  <TextField
                    className='w-full'
                    name='email'
                    placeholder='Enter email address'
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={Boolean(formError.email)}
                    helperText={formError.email}
                  />
                </Box>
                <Box className={cx(`input-label`,"mt-[25px]", "input-password")}>
                  <InputLabel className={cx('!text-[12px] text-[#2F1401] !font-normal !leading-[14.4px]')}>
                    Password
                  </InputLabel>
                  <TextField
                    className='w-full border-[#E3E7EA]'
                    name='password'
                    type={showPassword ? "text" : "password"}
                    placeholder='Password'
                    value={form.values.password}
                    onChange={form.handleChange}
                    error={Boolean(formError.password)}
                    helperText={formError.password}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className='mr-[20px]'
                          >
                            {
                              showPassword ? 
                              <img src={images.showPass} alt='' className={cx('w-[12px] h-[12px]')} /> :
                              <img src={images.hidePass} alt='' className={cx('w-[12px] h-[12px]')} />
                            }
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box className={cx('h-5 mt-[16px] flex items-center justify-between')}>
                  <FormControlLabel control={<Checkbox className='text-[16px] !text-[#8A97A0] !rounded-lg'
                    onChange={(event) => onSavePass(event.target.checked)} defaultChecked={isSavepass}
                    // icon={<><img src={images.checkbox_none} alt='' className={cx('w-[16px] h-[16px] inline-block')} /></>}
                  />} className='text-[#45B55]' label='Remember me' value='true' />

                  <Link href='forgot-password' underline='none' className={'w-[121px] text-[16px] !leading-[19.2px] !text-[#121055]'}>
                    Forgot password
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className={cx('mt-[30px]')}>
              <Button
                type='submit'
                variant='contained'
                //color='primary'
                sx={{
                  background:'#121055',
                  padding:"13px 24px",
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
              >
                Sign in
              </Button>

              <p className={'text-center text-[16px] mt-5'}>
                <span className={'text-[#554A42] font-normal'}>Don't have an account? </span>
                <Link href={adminRouteAbsolute.signUp} underline='none' className={'!font-medium !text-[#0E1E29] !text-bold'}>
                  Sign up
                </Link>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </AdminLogin>
  </div>;
};

export default SignInComponent;
