import classNames from 'classnames/bind';
import styles from './Orders.module.scss';

const cx = classNames.bind(styles);

const Orders = () => {
  return <>Orders Admin Page</>;
};

export default Orders;
