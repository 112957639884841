import axiosClient from './axiosClient';
import { IApiBaseResponse } from '~/utils/interface/common';
import {
  IParamsAddLead,
  IParamsGetLeads,
  IPayloadDeleteLead,
  IResponseGetLead,
  IResponseGetLeads,
  IResponseGetSelectLeads,
} from '~/utils/interface/lead';
import { IResponseLead, ILead } from '~/utils/interface/lead';

const leadApi = {
  getSelectLeads() {
    return axiosClient.get<IApiBaseResponse<IResponseGetSelectLeads>>('leads/selection');
  },
  addLead(data: IParamsAddLead) {
    const url = `leads/create`;
    return axiosClient.post<IApiBaseResponse<IResponseLead>>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  createLead(data: ILead) {
    return axiosClient.post<IApiBaseResponse<IResponseLead>>('leads/new', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getLeads(params: IParamsGetLeads) {
    const url = `leads`;
    return axiosClient.get<IApiBaseResponse<IResponseGetLeads>>(url, { params });
  },
  getLead(id: string) {
    return axiosClient.get<IApiBaseResponse<IResponseGetLead>>(`leads/${id}`);
  },
  updateLead(id: string, data: ILead) {
    return axiosClient.put<IApiBaseResponse<IResponseLead>>(`leads/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteLead(data: IPayloadDeleteLead) {
    const url = `leads`;
    return axiosClient.delete<IApiBaseResponse<null>>(url, { data });
  },

  
};

export default leadApi;
