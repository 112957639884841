export const Languages = ['en'];

export const TIME_OUT = 10 * 1000;

export const DEFAULT_PAGE = 1;
export const PREPARATION_THRESHOLD = 3;
export const ROWS_PER_PAGE = 10;
export const ITEMS_PER_BOARD_PAGE = 6;
export const STATUS_TABS: Array<string> = ['all', 'progress', 'hold', 'completed'];

export const DIALOG_MODE_CREATE = 'create';
export const DIALOG_MODE_EDIT = 'edit';

export const VALUE_TRUE = 'true';
export const VALUE_FALSE = 'false';
