import { Box, Button, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material';
import styles from './InviteSignup.module.scss';
import stylesAuth from '../Auth.module.scss';
import classNames from 'classnames/bind';
import { ILoginPayload } from '~/apis/auth';
import { images } from '~/assets';
import { useFormik } from 'formik';
import { useAppDispatch } from '~/utils/redux/hooks';
import {handleRegesterWithInvite } from '~/thunks/auth/authThunk';
import { IBodyInviteSignUp } from '~/utils/interface/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleApiError } from '~/utils/helper/common';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import AdminLogin from '~/layouts/admin/login/AdminLogin';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { checkInputError } from '~/utils/helper/auth';

const cx = classNames.bind(styles);
const cxAuth = classNames.bind(stylesAuth);

type Props = {};
const initialValues: IBodyInviteSignUp = {
  password: '',
  fullname: '',
  code: ''
};

const InviteSignupComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code: any = searchParams.get("code");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [formError, setFormError] = useState<IBodyInviteSignUp>({
    password: '',
    fullname: '',
    code: ''
  })

  const form: any = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      try {
        let valueSignUp: IBodyInviteSignUp = {
          code: code,
          password: values.password,
          fullname: values.fullname
        };

        //call dispatch here
        await dispatch(handleRegesterWithInvite({ body: valueSignUp })).unwrap();
        navigate(`${adminRouteAbsolute.login}`);
      } catch (e) {
        if (e instanceof AxiosError && e?.response?.data?.message) {
          let message: string = e?.response?.data?.message;
          let [isError, error] = checkInputError({
            password: '',
            fullname: ''
          }, message);

          if (isError) {
            return setFormError(error);
          }
        }
        handleApiError(e, setFieldError);
      }
    }
  });

  return <div id='signInComponent'>
    <AdminLogin>
      <Box className={cxAuth('relative z-10 ')}>
        <Box component='section' className={cxAuth('w-full h-full')}>
          <Box component='form' onSubmit={form.handleSubmit} className={cxAuth('max-w-[500px] w-full px-4 sm:px-0')}>
            <Box>
              <Box className={cxAuth('gap-5')}>
                <Box className={cxAuth('mt-5')}>
                  <Typography className={cxAuth('!font-medium !text-black !text-[36px] !leading-[43.2px]  font-medium text-center text-[#200E01]')} variant='h2'>
                    Create an account
                  </Typography>
                  <Typography
                    variant='body1'
                    className={cxAuth('!font-normal text-[16px] !leading-[19.2px] !text-[#3E5564] !mt-2.5 text-center text-[#554A42]')}
                  >
                    Enter your details to create an account
                  </Typography>
                </Box>
              </Box>
              <Box className={cxAuth('sm:mt-[30px] mt-6')}>
                <Box className={cxAuth(`input-label`)}>
                  <InputLabel className={cxAuth('!text-[12px] text-[#2F1401] !font-normal !leading-[14.4px]')}>
                    Full Name
                  </InputLabel>
                  <TextField
                    className='w-full'
                    name='fullname'
                    placeholder='Enter your name'
                    value={form.values.fullname}
                    onChange={form.handleChange}
                    error={Boolean(formError.fullname)}
                    helperText={formError.fullname}
                  />
                </Box>

                <Box className={cxAuth(`input-label`,"mt-[25px]", "input-password")}>
                  <InputLabel className={cxAuth('!text-[12px] text-[#2F1401] !font-normal !leading-[14.4px]')}>
                    Password
                  </InputLabel>
                  <TextField
                    className='w-full border-[#E3E7EA]'
                    name='password'
                    type={showPassword ? "text" : "password"}
                    placeholder='Password'
                    value={form.values.password}
                    onChange={form.handleChange}
                    error={Boolean(formError.password)}
                    helperText={formError.password}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className='mr-[20px]'
                          >
                            {
                              showPassword ? 
                              <img src={images.showPass} alt='' className={cxAuth('w-[12px] h-[12px]')} /> :
                              <img src={images.hidePass} alt='' className={cxAuth('w-[12px] h-[12px]')} />
                            }
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <InputLabel className={cx(`!text-[14px] text-[#8A97A0] !font-normal !leading-[14.4px] m-0  font-[Inter] ${formError.password ? "mt-0": "mt-4"}`)}>
                    Must be at least 8 characters
                  </InputLabel>
                </Box>
                            
              </Box>
            </Box>
            <Box className={cxAuth('mt-[30px]')}>
              <Button
                type='submit'
                variant='contained'
                //color='primary'
                sx={{
                  background:'#121055',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
              >
                Sign up
              </Button>
              
              <p className={'text-center text-[16px] mt-5'}>
                <span className={'text-[#3E5564] font-normal'}>Already have an account? </span>
                <Link href={adminRouteAbsolute.login} underline='none' className={'!font-medium !text-[#0E1E29] !text-bold'}>
                  Sign in
                </Link>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
      
    </AdminLogin>
  </div>;
};

export default InviteSignupComponent;