import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import styles from './ForgetPass.module.scss';
import { ForgetPassComponent } from '~/components/module/auth';


const cx = classNames.bind(styles);

const ForgetPass = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleLogin = () => {
    navigate(`${adminRouteAbsolute.dashboard}`);
  };
  //#endregion Handle Function
  return (
    <div id='signInPageAdmin'>
      <ForgetPassComponent />
    </div>
  );
};

export default ForgetPass;
