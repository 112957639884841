import { Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DropzoneProps, DropzoneRef, useDropzone } from 'react-dropzone';
import classNames from 'classnames/bind';
import styles from './BaseUploadFile.module.scss';
import { Pen2Icon, UploadIcon } from '~/assets/icons';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '~/utils/types/common';

export type BaseUploadFileProps = DropzoneProps &
  React.RefAttributes<DropzoneRef> & {
    onChange?: (files: Array<UploadFile>) => void;
    uploadedFiles?: Array<UploadFile>;
  };

const cx = classNames.bind(styles);
function BaseUploadFile(props: BaseUploadFileProps) {
  //#region Destructuring Props
  const { maxFiles = 1, ...restProps } = props;
  delete restProps.onChange;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation(['admin/component']);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    ...restProps,
    onDrop: (acceptedFiles: Array<File>) => {
      const updatedFiles = [
        ...uploadFiles,
        ...acceptedFiles.map((item) => ({
          file: item,
        })),
      ];
      setUploadFiles(updatedFiles);
      if (props.onChange) {
        props.onChange(updatedFiles);
      }
    },
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [uploadFiles, setUploadFiles] = useState<Array<UploadFile>>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (props?.uploadedFiles?.length) {
      setUploadFiles([...props.uploadedFiles.filter((uploadedFile) => !!uploadedFile?.url)]);
    }
  }, [props?.uploadedFiles]);
  //#endregion Implement Hook

  //#region Handle Function

  const renderPreviewComponent = (file: UploadFile, index: number) => {
    const fileUrl = file.url ?? (file.file ? URL.createObjectURL(file.file) : null);

    return (
      fileUrl && (
        <Box key={index} className={cx('preview-image-container')}>
          <Box component='img' src={fileUrl} className={cx('preview-image')}></Box>
          <Box onClick={() => removeImage(index)} className={cx('preview-change-image-button')}>
            <Pen2Icon className='w-[8px] h-[8px]' />
          </Box>
        </Box>
      )
    );
  };

  const removeImage = (imageIndex: number) => {
    const updatedFiles = uploadFiles.filter((_item, index) => index !== imageIndex);
    setUploadFiles(updatedFiles);

    if (props.onChange) {
      props.onChange(updatedFiles);
    }
  };
  //#endregion Handle Function
  return (
    <Box>
      {uploadFiles.length < maxFiles && (
        <Box className={cx('base-upload-file-container', '!h-[36px] !py-0 !px-[19.5px]')} {...getRootProps()}>
          <input {...getInputProps()} />
          <Box className={cx('upload-file-title')}>
            <UploadIcon className='w-[14px] h-[14px] mr-[6px]' />
            <Box className="text-[#121055] text-[12px]">
              {t('baseUploadFile.title')}
              {/* <Box component='span' className={cx('upload-file-title-browse')}>
                {t('baseUploadFile.browse')}
              </Box> */}
            </Box>
          </Box>
        </Box>
      )}
      {uploadFiles.map((file: UploadFile, index: number) => renderPreviewComponent(file, index))}
    </Box>
  );
}
export default memo(BaseUploadFile);
