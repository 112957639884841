import { createAsyncThunk } from '@reduxjs/toolkit';
import employeeApi from '~/apis/employee';
import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE,
  GET_EMPLOYEES,
  GET_SELECT_EMPLOYEES,
  EMPLOYEE_SALES,
} from '~/utils/constants/actionType';
import {
  IParamsGetEmployees,
  IResponseGetSelectEmployees,
  IResponseGetEmployees,
  IResponseGetEmployee,
} from '~/utils/interface/employee';
import { IResponseEmployee, IEmployee, IEmployeeInvite } from '~/utils/interface/employee';

export const handleGetSelectEmployees = createAsyncThunk<IResponseGetSelectEmployees>(
  GET_SELECT_EMPLOYEES,
  async (_params, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getSelectEmployees();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleCreateEmployee = createAsyncThunk<IResponseEmployee, IEmployee>(
  CREATE_EMPLOYEE,
  async (data: IEmployee, { rejectWithValue }) => {
    try {
      const res = await employeeApi.createEmployee(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const handleGetEmployees = createAsyncThunk<IResponseGetEmployees, IParamsGetEmployees>(
  GET_EMPLOYEES,
  async (params: IParamsGetEmployees = {}, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getEmployees(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const handleGetEmployee = createAsyncThunk<IResponseGetEmployee, string>(
  GET_EMPLOYEE,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getEmployee(id);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleEditEmployee = createAsyncThunk<IResponseEmployee, { id: string; params: IEmployee }>(
  UPDATE_EMPLOYEE,
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const res = await employeeApi.updateEmployee(id, params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleEmployeeSales = createAsyncThunk<any>(
  EMPLOYEE_SALES,
  async (params:any = {}, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getEmployeeSales();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const invite = createAsyncThunk<any, { employees: IEmployeeInvite[] }>(
  'employee/invite',
  async (data, { rejectWithValue }) => {
    try {
      const res = await employeeApi.inviteEmployees(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
