import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { handleEmployeeSales, handleGetEmployee, handleGetEmployees, handleGetSelectEmployees } from './employeeThunk';
import { IEmployeeList, ISelectEmployee } from '~/utils/interface/employee';
import { SortField } from '~/utils/types/common';
import { DEFAULT_PAGE, ROWS_PER_PAGE } from '~/utils/constants/common';

export interface EmployeeState {
  selectEmployees: Array<ISelectEmployee>;
  filter: IEmployeeFilter;
  currentPage: number;
  totalPage: number;
  needRefreshData: boolean;
  employees: Array<IEmployeeList>;
  selectedIds: Array<string>;
  id: string;
  openAlertDelete: boolean;
  menuSelectedId: string | undefined;
  sales?: any[]
}

interface IEmployeeFilter {
  page?: number;
  limit?: number;
  textSearch?: string;
  searchFields?: Array<string>;
  sort?: Array<SortField>;
  sortBy?: string;
  sortType?: string;
}

const initialState: EmployeeState = {
  selectEmployees: [],
  currentPage: DEFAULT_PAGE,
  totalPage: 0,
  needRefreshData: false,
  filter: {
    page: 1,
    limit: ROWS_PER_PAGE,
    textSearch: '',
    // searchFields: [],
    // sort: [],
    sortBy: '',
    sortType:'ASC',
  },
  selectedIds: [],
  employees: [],
  id: '',
  openAlertDelete: false,
  menuSelectedId: undefined,
  sales:[]
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    changeNeedRefreshData(state, action: PayloadAction<boolean>) {
      state.needRefreshData = action.payload;
    },
    setFilter(state, action: PayloadAction<IEmployeeFilter>) {
      state.filter = { ...state.filter };
      state.filter.page = action.payload.page
      state.filter.limit = action.payload.limit
      if (action.payload?.sort?.length) {
        state.filter.sortBy = action.payload.sort[0].field;
      }
    },
    changeSelectedIds(state, action: PayloadAction<Array<string>>) {
      state.selectedIds = [...action.payload];
    },
    changeEmployeeId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    changeOpenAlertDelete(state, action: PayloadAction<boolean>) {
      state.openAlertDelete = action.payload;
    },
    changeMenuSelectId(state, action: PayloadAction<string | undefined>) {
      state.menuSelectedId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handleGetSelectEmployees.pending, (_state, _action) => {})
      .addCase(handleGetSelectEmployees.fulfilled, (state, action) => {
        state.selectEmployees = [...action.payload.responses];
      })
      .addCase(handleGetEmployees.fulfilled, (state, action) => {
        state.employees = [...action.payload.responses];
        state.currentPage = action.payload.pagination.page;
        state.totalPage = action.payload.pagination.totalPage;
        state.needRefreshData = false;
      })

      .addCase(handleGetEmployee.fulfilled, (state, action) => {
        state.needRefreshData = false;
      })
      .addCase(handleGetSelectEmployees.rejected, (_state, _action) => {})
      .addCase(handleEmployeeSales.fulfilled, (state, action) => {
        state.sales = [...action.payload.responses];
      });
  },
});

export const employeeActions = employeeSlice.actions;

const employeeReducer = employeeSlice.reducer;
export default employeeReducer;
