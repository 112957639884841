import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/utils/redux/store';
import { StorageEnum } from '~/utils/enum/base';
import { handleLogin } from './authThunk';
import { IUserProfile } from '~/utils/interface/account';

export interface AuthState {
  accessToken?: string;
  user?: IUserProfile;
}

const initialState: AuthState = {};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogout(state) {
      localStorage.removeItem(StorageEnum.ACCESS_TOKEN);
      state.user = undefined;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserProfile(state, action){
      state.user = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(handleLogin.fulfilled, (_state, action) => {
      localStorage.setItem(StorageEnum.ACCESS_TOKEN, action.payload.data.token.access);
      localStorage.setItem(StorageEnum.REFRESH_TOKEN, action.payload.data.token.refresh);
    });
  },
});

export const authActions = authSlice.actions;

// export const selectUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const usetProfile = (state: RootState) => state.auth.user;

const authReducer = authSlice.reducer;
export default authReducer;
