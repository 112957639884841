import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { handleGetLead, handleGetLeads, handleGetSelectLeads } from './leadThunk';
import { ILeadList, ISelectLead } from '~/utils/interface/lead';
import { SortField } from '~/utils/types/common';
import { DEFAULT_PAGE, ROWS_PER_PAGE } from '~/utils/constants/common';
import { RootState } from '~/utils/redux/store';

export interface LeadState {
  selectLeads: Array<ISelectLead>;
  filter: ILeadFilter;
  currentPage: number;
  totalPage: number;
  needRefreshData: boolean;
  leads: Array<ILeadList>;
  selectedIds: Array<string>;
  id: string;
  openAlertDelete: boolean;
  menuSelectedId: string | undefined;
}

interface ILeadFilter {
  page?: number;
  limit?: number;
  textSearch?: string;
  searchFields?: Array<string>;
  sort?: Array<SortField>;
  sortBy?: string;
  sortType?: string;
}

const initialState: LeadState = {
  selectLeads: [],
  currentPage: DEFAULT_PAGE,
  totalPage: 0,
  needRefreshData: false,
  filter: {
    page: 1,
    limit: ROWS_PER_PAGE,
    textSearch: '',
    // searchFields: [],
    // sort: [],
    sortBy: '',
    sortType:'ASC',
  },
  selectedIds: [],
  leads: [],
  id: '',
  openAlertDelete: false,
  menuSelectedId: undefined,
};

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    changeNeedRefreshData(state, action: PayloadAction<boolean>) {
      state.needRefreshData = action.payload;
    },
    setFilter(state, action: PayloadAction<ILeadFilter>) {
      state.filter = { ...state.filter };
      state.filter.page = action.payload.page
      state.filter.limit = action.payload.limit
      if (action.payload?.sort?.length) {
        state.filter.sortBy = action.payload.sort[0].field;
        
      }
    },
    changeSelectedIds(state, action: PayloadAction<Array<string>>) {
      state.selectedIds = [...action.payload];
    },
    changeLeadId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    changeOpenAlertDelete(state, action: PayloadAction<boolean>) {
      state.openAlertDelete = action.payload;
    },
    changeMenuSelectId(state, action: PayloadAction<string | undefined>) {
      state.menuSelectedId = action.payload;
    },
    setLeadData(state, action){
      state.leads = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(handleGetSelectLeads.pending, (_state, _action) => {})
      .addCase(handleGetSelectLeads.fulfilled, (state, action) => {
        state.selectLeads = [...action.payload.responses];
      })
      .addCase(handleGetLeads.fulfilled, (state, action) => {
        state.leads = [...action.payload.responses];
        state.currentPage = action.payload.pagination.page;
        state.totalPage = action.payload.pagination.totalPage;
        state.needRefreshData = false;
      })

      .addCase(handleGetLead.fulfilled, (state, action) => {
        state.needRefreshData = false;
      })
      .addCase(handleGetSelectLeads.rejected, (_state, _action) => {});
  },
});

export const leadActions = leadSlice.actions;

const leadReducer = leadSlice.reducer;
export default leadReducer;
