import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export function notify(type: string, message: string) {
  let option = { toastId: 'bor-toast' };
  if (type === 'error') {
    return toast.error(message, option);
  }

  if (type === 'success') {
    return toast.success(message, option);
  }

  if (type === 'warning') {
    return toast.warning(message, option);
  }

  return toast(message, option);
}

export function catchError(e: unknown, handleError: (errorMessage: string) => void) {
  if (typeof e === 'string') {
    handleError(e);
  } else if (e instanceof AxiosError) {
    handleError(e?.response?.data?.message);
  } else if (e instanceof Error) {
    handleError(e.message);
  }
}

export function handleApiError(
  e: unknown,
  setFieldError?: (field: string, message: string | undefined) => void,
  isNotify: boolean = true
) {
  if (isNotify) {
    catchError(e, (message) => {
      notify('error', message || 'Something went wrong.');
    });
  }

  if (setFieldError && e instanceof AxiosError && e?.response?.data?.data) {
    for (let data of e.response.data.data) {
      setFieldError(data.field, data.message);
    }
  }
}

export function convertTextTimeToNumber(value: string) {
  const timeArray: Array<string> = value.toLowerCase().split('h');
  let hour: number = 0;
  let minute: number = 0;

  const time1st: number = +timeArray[0].replace(/[^0-9]+/g, '');

  // Case only has minutes
  if (timeArray[0].indexOf('m') > -1) {
    return time1st;
  }

  hour = time1st * 60;
  // Case has minutes
  if (timeArray[1]) {
    minute = +timeArray[1].replace(/[^0-9]+/g, '');
  }

  return hour + minute;
}

export function convertNumberToTime(number: number) {
  const hours = Math.floor(number / 3600);
  const minutes = Math.floor((number - hours * 3600) / 60);
  const seconds = number - hours * 3600 - minutes * 60;

  let hoursText = hours.toString();
  let minutesText = minutes.toString();
  let secondsText = seconds.toString();

  if (hours < 10) hoursText = '0' + hoursText;
  if (minutes < 10) minutesText = '0' + minutesText;
  if (seconds < 10) secondsText = '0' + secondsText;

  return hoursText + ':' + minutesText + ':' + secondsText;
}

export function getTimezone() {
  let timezone = 'UTC';
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.warn('Cannot get timezone');
  }

  return timezone;
}

/**
 * React doesn't work fine with null, undefined and string
 *
 * cast value from boolean true/false to string "true"/"false"
 * cast null and undefined to ""
 *
 * @param value
 * @returns
 */
export function cast(value: any) {
  if (typeof value === 'boolean') return String(value);

  return value ?? '';
}
