// import { NotFoundPage } from '~/components';
import {
  DashboardAdmin,
  // LoginAdmin,
  // SignUpAdmin,
  ScheduleAdmin,
  EmployeeAdmin,
  CRMAdmin,
  TaskAdmin,
  FormAdmin,
  InventoryAdmin,
  ProposalsAdmin,
  OrdersAdmin,
  ReportAdmin,
} from '~/pages';

import { IRouteModel } from '../interface/common';
import { SignInPageAdmin, SingUpPageAdmin, ForgetPassPageAdmin, ResetPassPageAdmin, InviteSignupPage } from '~/pages/admin';
// Router && Path
export const adminRoute = {
  base: '/admin',
  login: '/login',
  signUp: '/sign-up',
  dashboard: '/dashboard',
  forgetPass: '/forgot-password',
  resetPass: '/reset-password',
  employee: '/employee',
  addEmployee: '/add-employee',
  employeeDetail: '/:employeeId',
  editEmployee: '/edit-employee/:employeeId',
  cRM: '/crm',
  schedule: '/schedule',
  task: '/task',
  form: '/form',
  inventory: '/inventory',
  proposals: '/proposals',
  orders: '/orders',
  reports: '/reports',
  profile: '/profile',
  accountSetting: '/account-setting',
  components: '/components',
  message: '/message',
  inviteSignup: '/invite-signup',
};

//#region Admin Routes
export const publicAdminRoutes: IRouteModel[] = [
  {
    path: `${adminRoute.base}${adminRoute.signUp}`,
    component: SingUpPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.login}`,
    component: SignInPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.forgetPass}`,
    component: ForgetPassPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.resetPass}`,
    component: ResetPassPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.inviteSignup}`,
    component: InviteSignupPage,
  },
];

export const privateAdminRoutes: IRouteModel[] = [
  {
    path: `${adminRoute.base}${adminRoute.dashboard}`,
    component: DashboardAdmin,
    name: 'Dashboard',
  },
  {
    path: `${adminRoute.base}${adminRoute.employee}`,
    component: EmployeeAdmin,
    name: 'Employee',
    children: [
      {
        path: `${adminRoute.base}${adminRoute.employee}${adminRoute.addEmployee}`, // Add this nested route
        component: EmployeeAdmin,
        index: true,
      },
      {
        path: `${adminRoute.base}${adminRoute.employee}`, // Add this nested route
        component: EmployeeAdmin,
        index: true,
      },
      {
        path: `${adminRoute.base}${adminRoute.employee}${adminRoute.employeeDetail}`, // Add this nested route
        component: EmployeeAdmin,
        index: true,
      },
      {
        path: `${adminRoute.base}${adminRoute.employee}${adminRoute.editEmployee}`, // Add this nested route
        component: EmployeeAdmin,
        index: true,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.cRM}`,
    component: CRMAdmin,
    name: 'CRM',
  },
  {
    path: `${adminRoute.base}${adminRoute.schedule}`,
    component: ScheduleAdmin,
    name: 'Schedule',
  },
  {
    path: `${adminRoute.base}${adminRoute.task}`,
    component: TaskAdmin,
    name: 'Task',
  },
  {
    path: `${adminRoute.base}${adminRoute.form}`,
    component: FormAdmin,
    name: 'Form',
  },
  {
    path: `${adminRoute.base}${adminRoute.inventory}`,
    component: InventoryAdmin,
    name: 'Inventory',
  },
  {
    path: `${adminRoute.base}${adminRoute.proposals}`,
    component: ProposalsAdmin,
    name: 'Proposals',
  },
  {
    path: `${adminRoute.base}${adminRoute.orders}`,
    component: OrdersAdmin,
    name: 'Orders',
  },
  {
    path: `${adminRoute.base}${adminRoute.reports}`,
    component: ReportAdmin,
    name: 'Report',
  },
  

];
//#endregion Admin Routes

export const publicRoutes = [...publicAdminRoutes];

export const privateRoutes = [];

export const adminRouteAbsolute = {
  dashboard: `${adminRoute.base}${adminRoute.dashboard}`,
  login: `${adminRoute.base}${adminRoute.login}`,
  forgetPass: `${adminRoute.base}${adminRoute.forgetPass}`,
  resetPass:`${adminRoute.base}${adminRoute.resetPass}`,
  signUp: `${adminRoute.base}${adminRoute.signUp}`,
  employee: `${adminRoute.base}${adminRoute.employee}`,
  schedule: `${adminRoute.base}${adminRoute.schedule}`,
  task: `${adminRoute.base}${adminRoute.task}`,
  form: `${adminRoute.base}${adminRoute.form}`,
  inventory: `${adminRoute.base}${adminRoute.inventory}`,
  proposals: `${adminRoute.base}${adminRoute.proposals}`,
  orders: `${adminRoute.base}${adminRoute.orders}`,
  reports: `${adminRoute.base}${adminRoute.schedule}`,
  profile: `${adminRoute.base}${adminRoute.profile}`,
  accountSetting: `${adminRoute.base}${adminRoute.accountSetting}`,
  message: `${adminRoute.base}${adminRoute.message}`,
};
