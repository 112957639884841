import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { urlRefreshToken } from '~/utils/constants/apis';
import { baseURL } from '~/utils/constants/env';
import { HTTP_RESPONSE } from '~/utils/constants/http-response';
import { httpResponse, httpStatusCode, skipHttpResponseArray } from '~/utils/constants/httpResponse';
import { MethodRequestServerEnum, StorageEnum } from '~/utils/enum/base';
import { getTimezone } from '~/utils/helper';
import { IRefreshTokenResponse } from '~/utils/interface/auth';
import { IBaseErrorData, IBaseResponse } from '~/utils/interface/base';
import { adminRouteAbsolute } from '~/utils/routes/admin';


interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    // Do something before request is sent
    const token = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Bor-Timezone'] = getTimezone();
    return config;
  },

  function (error: AxiosError | Error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * ==============================================================
 * Helper Functions
 * ==============================================================
 */
const isUnAuthorizedError = (error: AxiosError) => {
  return error?.response?.status === httpStatusCode.UNAUTHORIZED;
};

const forceLogout = () => {
  localStorage.clear();
  window.location.href = adminRouteAbsolute.login;
};

const getRefreshToken = async (refreshToken: string) => {
  try {
    const resp = await axiosClient.post<IBaseResponse<IRefreshTokenResponse>>(urlRefreshToken, {
      refreshToken,
    });

    return resp?.data?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * ==============================================================
 * Error Handling
 * ==============================================================
 */
const handleUnauthorizedError = async (originalRequest: AxiosRequestConfig) => {
  const currentRefreshToken = localStorage.getItem(StorageEnum.REFRESH_TOKEN);

  if (!isRefreshing && currentRefreshToken) {
    isRefreshing = true;

    try {
      const refreshResponse = await getRefreshToken(currentRefreshToken);
      const { access, refresh } = refreshResponse;

      localStorage.setItem(StorageEnum.ACCESS_TOKEN, access);
      localStorage.setItem(StorageEnum.REFRESH_TOKEN, refresh);

      // Ensure headers exist before assignment
      if (originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${access}`;
      }

      // Retry all requests in the queue with the new token
      refreshAndRetryQueue.forEach(({ config, resolve, reject }: RetryQueueItem) => {
        axiosClient
          .request(config)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });

      // Clear the queue
      refreshAndRetryQueue.length = 0;

      // Retry the original request
      return axiosClient(originalRequest);
    } catch (error) {
      forceLogout();
    } finally {
      isRefreshing = false;
    }
  } else {
    // Add the original request to the queue
    return new Promise<void>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }
};

/**
 * ==============================================================
 * Request handle functions
 * ==============================================================
 */
const onRequestFulfilled = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

const onRequestRejected = (error: AxiosError | Error) => {
  return Promise.reject(error);
};

/**
 * ==============================================================
 * Response handle functions
 * ==============================================================
 */
const onResponseFulfilled = (response: AxiosResponse) => {
  const { method } = response.config;
  const { code, message } = response.data;

  if (method === MethodRequestServerEnum.GET) {
    return response;
  }

  if (skipHttpResponseArray.includes(code)) {
    return response;
  }

  // const displayMessage = httpResponse[code] || message;
  // Handle show Toast here!
  return response;
};

const onResponseRejected = (error: AxiosError | Error) => {
  // Handle Exception case
  if (!isAxiosError(error)) {
    return Promise.reject(error);
  }

  const originalRequest: AxiosRequestConfig | undefined = error.config;
  if (!originalRequest) {
    return Promise.reject(error);
  }

  // Handle Unauthorize case
  if (isUnAuthorizedError(error)) {
    return handleUnauthorizedError(originalRequest);
  }

  // Handle display error case
  const { code, message } = error?.response?.data as IBaseErrorData;
  // const displayMessage = httpResponse[code] || message;
  // Handle show Toast here!
  return Promise.reject(error);
};

// Add a request interceptor
axiosClient.interceptors.request.use(onRequestFulfilled, onRequestRejected);

// Add a response interceptor
axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);


export default axiosClient;
