import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleGetProductType } from "./productThunk";
import { IProductType } from "~/utils/interface/product";

export interface ProductState {
    productType: Array<IProductType>
}

interface ILeadFilter {

}

const initialState: ProductState = {
    productType: []
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    builder
    .addCase(handleGetProductType.fulfilled, (state, action) => {
        state.productType = [...action.payload.responses];
    });
  },
});

export const productActions = productSlice.actions;

const productReducer = productSlice.reducer;
export default productReducer;
