import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import { images } from '~/assets';
import classNames from 'classnames/bind';
//import styles from '~/components/module/auth/signIn/SignIn.module.scss';
import styles from './AdminLogin.module.scss';
import { ReactNode, useState } from 'react';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import { StorageEnum } from '~/utils/enum/base';


const cx = classNames.bind(styles);
type Props = {
  isHideLogo?:boolean,
  children?: ReactNode;
};

const AdminLogin = (props:Props) => {
  const {isHideLogo} = props;
  const [isLoggedIn] = useState<boolean>(!!localStorage.getItem(StorageEnum.ACCESS_TOKEN));

  const { children } = props;
  return <>
  {
    isLoggedIn ? <Navigate to={adminRouteAbsolute.dashboard} /> 
    : <Box className={cx('w-full', 'h-screen sm:h-screen','flex')}>
        <Box className={cx("w-full", "h-screen", "bg-[#FAFAFA]", "flex", "items-center", "justify-center", "relative")} >
          <div className={cx('bg-logo')}></div>
          <div className='bg-[#ffffff] shadow-[0px_20px_60px_0px_#01002205] p-[60px] rounded-[20px] z-[1] w-[620px]'>
            {
              !isHideLogo &&< img src={images.logo} alt='' className={cx('w-[274px] h-[60px] m-auto mb-[40px]')} />
            }
            
           {children}
          </div>
          
        </Box>
        
        
      </Box>
  }
    
  </>;
};

export default AdminLogin;
