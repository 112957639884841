import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { adminRoute, privateAdminRoutes, publicAdminRoutes } from './utils/routes/admin';
import { privateUserRoutes, publicUserRoutes, userRoute } from './utils/routes/user';
import { publicRoutes } from './utils/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminMainLayout } from './layouts';

type Props = {};

const App = (props: Props) => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Admin public route */}
        <Route path={userRoute.base} element={<Navigate to={`${adminRoute.base}${adminRoute.login}`} />}></Route>
        <Route path={adminRoute.base} element={<Navigate to={`${adminRoute.base}${adminRoute.login}`} />}></Route>
        <Route path={adminRoute.base}>
          {publicAdminRoutes.map((route, index) => {
            const Page = route.component || '';
            return (
              <Route key={index} path={route.path} element={<Page />}>
                {route.children &&
                  route.children.length > 0 &&
                  route.children.map((childRoute, index) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={index}
                        path={childRoute.path}
                        index={childRoute.index ?? false}
                        element={<ChildComponent />}
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Route>
        {/* Private route */}
        <Route path={adminRoute.base} element={<AdminMainLayout />}>
          {privateAdminRoutes.map((route, index) => {
            const Page = route.component || '';
            return (
              <Route key={index} path={route.path} element={<Page />}>
                {route.children &&
                  route.children.length > 0 &&
                  route.children.map((childRoute, index) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={index}
                        path={childRoute.path}
                        index={childRoute.index ?? false}
                        element={<ChildComponent />}
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Route>
      </Routes>
      <ToastContainer newestOnTop autoClose={5000} theme='light' limit={1} />
    </BrowserRouter>
  );
};

export default App;
