export const INTERESTED : Array<{ id: string; name: string }> = [
    { id: 'awnings', name: 'Awnings' },
    { id: 'patio-shades', name: 'Patio Shades' },
    { id: 'window-screens', name: 'Window Screens' }
];

export const  SALES_REP : Array<any> = [
    { id: '11', name: 'Chris I.', type:"123"},
    { id: '112', name: 'Jose V', type:"123" },
    { id: '13', name: 'Josh Y' , type:"123"},
    { id: '14', name: 'Josh H.', type:"123" },
    { id: '15', name: 'Kevin E.', type:"123" }
];

export const  ABOUT_US : Array<{ id: string; name: string }> = [
    { id: 'angies-list', name: 'Angies List.' },
    { id: 'billboard', name: 'Billboard' },
    { id: 'chris-shutter', name: 'Chris Shutter and Blinds' },
    { id: 'flyer', name: 'Door Hanger/Flyer' },
    { id: 'erika', name: 'Erika Thompson Creative Interior Designer' },
    { id: 'facebook', name: 'Facebook' },
    { id: 'home-show', name: 'Home Show' }
];

export const  SORTTEST : Array<string> = [
    "ITEM1","ITEM2","ITEM4","ITEM3","ITEM8","ITEM7"
];
