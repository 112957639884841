import classNames from 'classnames/bind';
import styles from './Proposals.module.scss';

const cx = classNames.bind(styles);

const Proposals = () => {
  return <>Proposals Admin Page</>;
};

export default Proposals;
