import { createTheme } from '@mui/material/styles';
import variables from '~/components/common/globalStyles/_variables.module.scss';
import { CheckboxCheckedIcon, CheckboxIcon } from './assets/icons';

export const appTheme = createTheme({
  typography: {
    fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      light: '#121055',
      main: '#121055',
      dark: '#121055',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#EBEAF5',
      main: '#EBEAF5',
      dark: '#EBEAF5',
      contrastText: '#758CA5',
    },
    info: {
      light: '#10a479',
      main: '#10a479',
      dark: '#10a479',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#d52b1e',
      main: '#d52b1e',
      dark: '#d52b1e',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true, variant: 'outlined' },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon className='w-[17px] h-[17px]' />,
        checkedIcon: <CheckboxCheckedIcon className='w-[17px] h-[17px]' />,
      },
    },
  },
});
