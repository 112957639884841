import { Box, Button, Checkbox, css, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material';
import styles from '../Auth.module.scss';
import classNames from 'classnames/bind';
import { ILoginPayload } from '~/apis/auth';
import { images } from '~/assets';
import { useFormik } from 'formik';
import { useAppDispatch } from '~/utils/redux/hooks';
import { handleForgetPass } from '~/thunks/auth/authThunk';
import { IBodyForgetpass } from '~/utils/interface/auth';
import { redirect, useNavigate } from 'react-router-dom';
import { handleApiError } from '~/utils/helper/common';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import AdminLogin from '~/layouts/admin/login/AdminLogin';
import { useState } from 'react';
import { checkInputError } from '~/utils/helper/auth';
import { AxiosError } from 'axios';

const cx = classNames.bind(styles);

type Props = {};
const initialValues: IBodyForgetpass = {
  email: '',
};

const ForgetPassComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [step,setStep] = useState(1);

  const [formError, setFormError] = useState<IBodyForgetpass>({
    email: ''
  })

  const form:any = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      try {
        let valueLogin:IBodyForgetpass ={
          email: values.email,
        }
        await dispatch(handleForgetPass({body:valueLogin})).unwrap();
        navigate(`${adminRouteAbsolute.login}`);
        // setStep(2)
      } catch (e) {
        if(e instanceof AxiosError && e?.response?.data?.message)
          {
            let message:string = e?.response?.data?.message;
            let [isError, error] = checkInputError({
              email: '',
            },message );
            
            if(isError){
              return setFormError(error);
            }
          }
        handleApiError(e, setFieldError);
      }
    },
  });

  const onGotoResset = () => {
    navigate(`${adminRouteAbsolute.resetPass}`);
  }

  return <div id='signInComponent'>
    <AdminLogin>
      {
        step == 1 &&  <Box className={cx('relative z-10 ')}>
        <Box component='section' className={cx('w-full h-full')}>
          <Box component='form' onSubmit={form.handleSubmit} className={cx('max-w-[500px] w-full px-4 sm:px-0')}>
            <Box>
              <Box className={cx('gap-5')}>
                
                <Box className={cx('mt-5')}>
                  <Typography className={cx('!font-medium !text-black !text-[36px] !leading-[43.2px]  font-medium text-center text-[#200E01]')} variant='h2'>
                    Forgot Password
                  </Typography>
                  <Typography
                    variant='body1'
                    className={cx('!font-normal text-[16px] !leading-[19.2px] !text-[#3E5564] !mt-2.5 text-center text-[#554A42]')}
                  >
                    No worries, we’ll send you reset instructions.
                  </Typography>
                </Box>
              </Box>
              <Box className={cx('sm:mt-[30px] mt-6')}>
                <Box className={cx(`input-label`)}>
                  <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                    Email
                  </InputLabel>
                  <TextField
                    className='w-full'
                    name='email'
                    placeholder='Enter email address'
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={Boolean(formError.email)}
                    helperText={formError.email}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={cx('mt-[30px]')}>
              <Button
                type='submit'
                variant='contained'
                //color='primary'
                sx={{
                  background:'#121055',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>

      </Box>
      }
      {
        step == 2 &&  <Box className={cx('relative z-10 ')}>
        <Box component='section' className={cx('w-full h-full')}>
          <Box component='form' onSubmit={form.handleSubmit} className={cx('max-w-[500px] w-full px-4 sm:px-0')}>
            <Box>
              <Box className={cx('gap-5')}>
                
                <Box className={cx('mt-5')}>
                  <Typography className={cx('!font-medium !text-black !text-[36px] !leading-[43.2px]  font-medium text-center text-[#200E01]')} variant='h2'>
                    Enter OTP Code
                  </Typography>
                  <Typography
                    variant='body1'
                    className={cx('!font-normal text-[16px] !leading-[19.2px] !text-[#3E5564] !mt-2.5 text-center text-[#554A42]')}
                  >
                    We sent OTP code to eleanor.pena@gmail.com
                  </Typography>
                </Box>
              </Box>
              <Box className={cx('sm:mt-[30px] mt-6')}>
                
                <Box className={cx( `input-label`,`input-otp`,'flex justify-between')}>
                  <Box className='w-[75px]'>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}
                      
                    />
                  </Box>
                  <Box className='w-[75px]'>
                    <TextField
                      
                    />
                  </Box>
                  <Box className='w-[75px]'>
                    <TextField
                      
                    />
                  </Box>
                  <Box className='w-[75px]'>
                    <TextField
                      
                    />
                  </Box>
                  <Box className='w-[75px]'>
                    <TextField
                      
                    />
                  </Box>
                  <Box className='w-[75px]'>
                    <TextField
                      
                    />
                  </Box>
                  
                  
                </Box>
              </Box>
            </Box>
            <Box className={cx('mt-[30px]')}>
              <Button
                variant='contained'
                //color='primary'
                sx={{
                  background:'#121055',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
                onClick={onGotoResset}
              >
                Continue
              </Button>

              <p className={'text-center text-[16px] mt-5'}>
                <span className={'text-[#3E5564] font-normal'}>Didn’t receive the OTP ? </span>
                <Link href={adminRouteAbsolute.login} underline='none' className={'!font-medium !text-[#0E1E29] !text-bold'}>
                  Click to resend
                </Link>
              </p>
            </Box>
          </Box>
        </Box>

      </Box>
      }
    </AdminLogin>
  </div>;
};

export default ForgetPassComponent;
