import { createAsyncThunk } from '@reduxjs/toolkit';
import productApi from '~/apis/product';
import { GET_PRODUCT_TYPE } from '~/utils/constants/actionType';
import { IResponseGetProductTypes } from '~/utils/interface/product';


export const handleGetProductType = createAsyncThunk<IResponseGetProductTypes>(
    GET_PRODUCT_TYPE,
    async (_params, { rejectWithValue }) => {
        try {
        const res = await productApi.getProductTypes();
        return res.data.data;
        } catch (error) {
        return rejectWithValue(error);
        }
    }
);

