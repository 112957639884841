import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './SelectSort.module.scss';
import classNames from 'classnames/bind';
import { FilterIcon, SortIcon } from '~/assets/icons';
import BaseSelect from '~/components/common/base-select/BaseSelect';
import { SelectOption, SortField } from '~/utils/types/common';
import { useState, memo, useEffect } from 'react';
import { isEqual } from 'lodash';

export type SelectSortProps = {
  name?: string;
  searchFields: Array<string>;
  searchInput?: string;
  onApply?: (searchFields: Array<SortField>) => void;
  parentName?: string;
  sort?: Array<SortField>;
};

const cx = classNames.bind(styles);

const SelectSort = (props: SelectSortProps) => {
  //#region Destructuring Props
  const { parentName } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  const { t } = useTranslation([`admin/${parentName}`, 'admin/common']);
  //#endregion Selector

  //#region Declare State
  const [searchFields, setSearchFields] = useState<Array<string>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>();
  const options: Array<SelectOption> = props.searchFields.map((value: string) => {
    return {
      id: value,
      name: t(`properties.${value}`),
    };
  });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (props.sort) {
      const parentSearchFields = props.sort.filter((item) => item.type === 'ASC').map((item) => item.field);
      if (!isEqual(parentSearchFields.sort(), searchFields)) {
        setSearchFields(parentSearchFields);
        setActive(!!parentSearchFields.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sort]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderSelectedOptionComponent = (item: SelectOption) => {
    return (
      <Box className={cx('event-select-sortBy-container')}>
        <Box className={cx('event-select-sortBy')}>
          <SortIcon className='w-[13px] h-[13px]' />
          <Box className='ml-[5px]'>{t('sortBy', { ns: 'admin/common' })}</Box>
        </Box>
      </Box>
    );
  };

  const renderBottomComponent = () => {
    return (
      <Box className={cx('button')}>
        <Button className={cx('button-text')} onClick={handleValueChangeSortby}>
          {t('applySort', { ns: 'admin/common' })}
        </Button>
      </Box>
    );
  };

  const renderTopComponent = () => {
    return (
      <Box>
        <Typography className='mb-[16px] text-[14px]/[16.8px] text-[#3A3F51] font-medium'>
          {t('sortBy', { ns: 'admin/common' })}
        </Typography>
      </Box>
    );
  };

  const handleChange = (event: React.ChangeEvent<{ value: Array<string> }>) => {
    setSearchFields(event.target.value);
  };
  const handleValueChangeSortby = () => {
    if (props?.onApply) {
      setActive(!!searchFields.length);
      const applySearchFields: Array<SortField> = searchFields.map((field: string) => ({
        field: field,
        type: 'ASC',
      }));
      props?.onApply(applySearchFields);
      handleClose();
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //#endregion Handle Function

  return (
    <>
      <BaseSelect
        onChange={(item) => handleChange(item)}
        value={searchFields}
        className={cx({ 'active-select': active }, ' bg-white rounded-[8px] !h-[40px]')}
        menuClassName={cx('select-filter-menu')}
        options={options}
        isHideIcon={true}
        multiple
        isSearchEnable={true}
        renderSelected={(item: SelectOption) => renderSelectedOptionComponent(item)}
        bottomComponent={() => renderBottomComponent()}
        topComponent={() => renderTopComponent()}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </>
  );
};

export default memo(SelectSort);
