import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './SelectRadio.module.css';
import classNames from 'classnames/bind';
import { CloseIcon, FilterIcon, SearchIcon } from '~/assets/icons';
import BaseSelect from '~/components/common/base-select/BaseSelect';
import { SelectOption } from '~/utils/types/common';
import { useState, memo, useEffect } from 'react';

type OptionType = {
  id:string;
  name:string;
}

export type SelectRadioProp = {
  name?: string;
  options: Array<OptionType>;
  search?: string;
  onChange?:(values:Array<OptionType>)=>void;
  onApply?: (searchInput: string, searchFields: Array<OptionType>) => void;
  parentName?: string;
  renderOptionItem?: (item: OptionType, isSelect:boolean , index: number) => React.ReactElement;
};

const cx = classNames.bind(styles);

const SelectRadio =  (props: SelectRadioProp) => {
  //#region Destructuring Props
  const { parentName } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  const { t } = useTranslation([`admin/${parentName}`, 'admin/common']);
  //#endregion Selector

  //#region Declare State
  const [searchFields, setSearchFields] = useState([]);
  const [selectValue, setSelectValue] = useState<OptionType[]>([]);
  const [unselectValue, setUnselectValue] = useState<OptionType[]>(props.options);
  const [optionData, setOptionData] = useState<any>({});


  const [inputSearch, setInputSearch] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<boolean>();
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  useEffect(()=>{
    const _optionData:any = {};
    props.options.map((item) => {
      _optionData[item.id] = {item , isSelect: false};
    });

    setOptionData(_optionData);
    setSelectValue([]);
   // updateOptionSlect();
  },[props.options])

  useEffect(()=>{
    updateOptionSlect();
  },[optionData])
  

  //#region Handle Function
  const renderSelectedOptionComponent = (_item: SelectOption) => {
    return (
      <Box className={cx('select-filter-container')}>
        <Box className={cx('event-select-filter-container')}>
          <Box className={cx('event-select-filter')}>
            <Box className='ml-[10px] text-[#8A97A0] text-[12px] font-[Inter]'>{t('Select', { ns: 'admin/common' })}</Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderBottomComponent = () => {
    return (
      <Box className={cx('button')}>
        <Button className={cx('button-text')} onClick={handleValueChange}>
          {t('applyFilter', { ns: 'admin/common' })}
        </Button>
      </Box>
    );
  };

  const renderTopComponent = () => {
    return (
      <Box>
        <TextField
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={t('searchPlaceholder')}
          className='mb-[16px]'
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          InputProps={{ startAdornment: <SearchIcon className='w-[16px]' /> }}
        />
        <Typography className='mb-[16px] text-[14px]/[16.8px] text-[#3A3F51] font-medium'>
          {t('filter', { ns: 'admin/common' })}
        </Typography>
      </Box>
    );
  };

  const renderRadioOptionItem = (item:OptionType, isSelect:boolean ,index: number)=>{
    if(props.renderOptionItem)
        return props.renderOptionItem(item,isSelect ,index);
    return <div className='text-[12px]'>{item.name} {item.id}</div>
  }

  const unSelectOption = (item:OptionType, index: number)=>{

    
    setSelectValue( selectValue.filter((it:any) => it != item) )
    optionData[item.id] && (optionData[item.id].isSelect = false);

    updateOptionSlect();
  }

  

  const handleChange = (item: any) => {
    const value = item.target.value;
   // setSearchFields(value);
    let valueSelect:OptionType[] = [...selectValue];
    value.map((item:string)=>{
      optionData[item].isSelect = true;
      valueSelect.push(optionData[item].item)
    })

    setSelectValue(valueSelect);
    
    if (props?.onChange) {
      props?.onChange(valueSelect);
    }

    updateOptionSlect();
    
  };

  const updateOptionSlect = () => {
    
    let valueUnSelect:OptionType[] = [];
    props.options.map((item)=>{
      if(!optionData[item.id] || optionData[item.id].isSelect) return;
      valueUnSelect.push(optionData[item.id].item)
    })
    setUnselectValue(valueUnSelect);
  }

  const handleValueChange = () => {
    setActive(!!searchFields.length);

    if (props?.onApply) {
      props?.onApply(inputSearch, searchFields);
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#endregion Handle Function

  //#region Declare State


  

  return (
    <>
      <BaseSelect
        onChange={(item) => handleChange(item)}
        value={[]}
        className={cx({ 'active-select': active })}
        menuClassName={cx('select-filter-menu')}
        options={unselectValue}
        multiple
        renderSelected={(item: SelectOption) => renderSelectedOptionComponent(item)}
        renderOptionItem={(item:any, index: number)=> renderRadioOptionItem(item, false, index)}
        // topComponent={() => renderTopComponent()}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
      <div className='flex flex-wrap'>
        {
          selectValue.map((item,i)=>{
            return <div className='flex items-center h-[24px] rounded-[12px] bg-[#F0F9FF] w-fit px-[10px] mr-[20px] mt-[10px]'>
              {renderRadioOptionItem(item,true, i)}
              <CloseIcon className='w-[12px] h-[10px] ml-[10px]'  onClick={() => {unSelectOption(item, i)}}/>
            </div> 
          })
        }
      </div>
    </>
  );
};

export default memo(SelectRadio);
