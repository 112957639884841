import classNames from 'classnames/bind';
import styles from './Form.module.scss';

const cx = classNames.bind(styles);

const Form = () => {
  return <>Form Admin Page</>;
};

export default Form;
