import { Box, Button, IconButton, Modal, ModalProps } from '@mui/material';
import { CloseIcon, TrashContainedIcon } from '~/assets/icons';
import styles from './ModalAlertDelete.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const cx = classNames.bind(styles);

type ModalAlertDeleteProps = Omit<ModalProps, 'children'> & {
  title?: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ModalAlertDelete = (props: ModalAlertDeleteProps) => {
  const { title, description, onCancel, onConfirm, ...restProps } = props;
  const { t } = useTranslation('admin/common');

  return (
    <Modal {...restProps}>
      <Box className={cx('modal-container')}>
        <IconButton className='absolute top-[10px] right-[10px]' onClick={onCancel}>
          <CloseIcon className='w-[10px] h-[10px]' />
        </IconButton>

        <Box className={cx('content-container')}>
          <Box className='p-[12px] bg-[#F9E0DE] rounded-[8px]'>
            <TrashContainedIcon className='w-[34px] h-[34px]' />
          </Box>

          <Box className={cx('alert-title')}>{title ?? t('alertTitle')}</Box>
          <Box className={cx('alert-description')}>{description}</Box>
        </Box>

        <Box className={cx('footer-container')}>
          <Button
            className='text-[#758CA5] bg-[#F0F7FF] border-0 h-[36px] flex-1 hover:border-none'
            variant='outlined'
            disableElevation
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button className='ml-[10px] flex-1' variant='contained' disableElevation onClick={onConfirm}>
            {t('confirmDelete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(ModalAlertDelete);
