import React, { useState, memo, useCallback, useMemo, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { MenuVerticalIcon } from '~/assets/icons';
import styles from './EmployeeList.module.scss';
import classNames from 'classnames/bind';
import { useAppDispatch, useAppSelector } from '~/utils/redux/hooks';
import { employeeActions } from '~/thunks/employee/employeeSlice';
import { HeadCelType, SortDirection } from '~/utils/types/common';
import MenuOption from '~/components/employees/menu-option/MenuOption';
import { IEmployee, IEmployeeList } from '~/utils/interface/employee';
import { useTranslation } from 'react-i18next';
import EmployeeCard from '~/components/employees/EmployeeCard';
import { BaseHeaderTitle, SelectFilter, SelectSort } from '~/components/common';
import { Box, Button, TextField } from '@mui/material';
import { PlusIcon, SearchIcon, TrashIcon } from '~/assets/icons';
import { SEARCH_FIELDS, SORT_FIELDS } from '~/utils/constants/employee';
import styleColor from '~/assets/icons/icon.module.scss';
import { useNavigate } from 'react-router-dom';
import useDebounce from '~/hooks/useDebounce';

const cxColor = classNames.bind(styleColor);
const LIMIT_RECORD = 99999;
type EmployeeListProps = {
};

function EmployeeList(props: EmployeeListProps) {
  const { t } = useTranslation(['admin/account']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const navigate = useNavigate();
  const searchInputDebounce = useDebounce<string>(searchInput);
  const { employees, filter, currentPage, totalPage } = useAppSelector((state) => state.employee);
  const handleValueChange = (valueSearchInput: string, searchFields: Array<string>) => {
    dispatch(
      employeeActions.setFilter({
        textSearch: valueSearchInput,
        // searchFields: searchFields,
        page: 1,
      })
    );
  };

  const headCells: Array<HeadCelType> = useMemo(
    () => [
      {
        id: 'name',
        label: t('name'),
        sortable: false,
        searchable: true,
      },
      {
        id: 'employeeId',
        label: t('employeeID'),
        sortable: false,
        searchable: true,
      },
      {
        id: 'email',
        label: t('email'),
        sortable: false,
        searchable: true,
      },
      {
        id: 'role',
        label: t('role'),
        sortable: false,
        searchable: true,
      },
      {
        id: 'date',
        label: t('date'),
        sortable: false,
        searchable: true,
      },
      {
        id: 'performance',
        label: t('performance'),
        sortable: false,
        searchable: true,
      },
      {
        id: '',
        label: '',
        sortable: false,
        searchable: false,
      },
    ],
    [t]
  );

  
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    navigate('add-employee');
  };

  useEffect(() => {
    if (dispatch && typeof searchInputDebounce !== 'undefined') {
      dispatch(
        employeeActions.setFilter({
          textSearch: searchInputDebounce,
          // searchFields: ['name'],
          limit: LIMIT_RECORD
        })
      );
    }
  }, [searchInputDebounce, dispatch]);

  return (
    <Box>
      <Box className=''>
        <Box className='flex flex-col min-[1080px]:flex-row min-[1080px]:flex-1 min-[1080px]:justify-between mb-9'>
          <BaseHeaderTitle title={t('title')} />
          <Button
            className='h-[36px] px-[14px] py-[9.5px] text-[14px]/[16.8px] font-medium rounded-[8px] ml-[10px]'
            variant='contained'
            disableElevation
            startIcon={<PlusIcon className={cxColor('w-[10px] h-[10px]', 'icon-fill-ffffff')} />}
            onClick={handleOpen}
          >
            {t('addEmployee')}
          </Button>
        </Box>

        <Box className="flex gap-4">
            <TextField
              className='!w-[430px] bg-white rounded-[8px] h-[40px]'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={"Search by name or role..."}
              InputProps={{ startAdornment: <SearchIcon className='w-[16px]' /> }}
            />

          <Box className='flex flex-row mt-[10px] min-[1080px]:flex-row min-[1080px]:ml-[10px] min-[1080px]:mt-0'>
            <Box className='w-[80px]'>
              <SelectFilter 
                name='searchFields'
                searchFields={SEARCH_FIELDS}
                search={searchInput}
                onApply={handleValueChange}
                parentName={'account'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 py-8">
        {[...employees].map((employee: IEmployee) => (
          <EmployeeCard key={employee.id} employee={employee} onClick={() => {
            console.log('click')
            navigate(`${employee.id}`);
          }} />
        ))}
      </Box>
    </Box>
  );
}

export default memo(EmployeeList);