import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  Chip,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  Typography,
} from '@mui/material';
import styles from './BaseSelect.module.scss';
import classNames from 'classnames/bind';
import { ArrowDownIcon, SearchIcon } from '~/assets/icons';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export type BaseSelectProps<T extends Record<string, any>> = BoxProps &
  SelectProps & {
    value?: any;
    label?: string | undefined;
    placeholder?: string | undefined;
    renderSelected?: (item: T) => React.ReactElement;
    onChange?: (...event: any[]) => void;
    options?: Array<T>;
    renderOptionItem?: (item: T, index: number) => React.ReactElement;
    menuClassName?: string;
    isSearchEnable?: boolean;
    isHideIcon?: boolean;
    bottomComponent?: () => React.ReactElement;
    topComponent?: () => React.ReactElement;
    helperText?: string;
  };

const BaseSelect = <T extends Record<string, any>>(props: BaseSelectProps<T>) => {
  //#region Destructuring Props
  const {
    isHideIcon = false,
    isSearchEnable = false,
    value,
    label,
    placeholder,
    renderSelected,
    onChange,
    options,
    className,
    ...restProps
  }: BaseSelectProps<T> = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation('admin/component');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOptions, setSelectOptions] = useState<Array<T>>();
  const [searchValue, setSearchValue] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  const getFilterOption = useCallback((options: Array<T>, value: string) => {
    return options.filter((item) => {
      return item.name?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
    });
  }, []);
  useEffect(() => {
    if (options?.length) {
      setSelectOptions(getFilterOption(options, searchValue));
    }
    else{
      setSelectOptions([]);
    }
  }, [options, getFilterOption, searchValue]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderOptionItem =
    props.renderOptionItem ||
    function (option: T, _index: number) {
      let isChecked = value === option.id;
      if (Array.isArray(value)) isChecked = value.indexOf(option.id) > -1;

      return (
        <Box className='select-option'>
          {/* <Checkbox checked={isChecked} className='mr-[7px]' /> */}
          {option.name}
        </Box>
      );
    };

  const renderSelectedItem =
    renderSelected ||
    function (selectedItem) {
      if (Array.isArray(selectedItem)) {
        const selectedComponent = selectedItem.map((item) => {
          return <Chip key={item.id} label={item.name} variant='outlined' size='small' className='mr-[5px]' />;
        });
        return <Box className='p-[15px]'>{selectedComponent}</Box>;
      }
      return <Box className='p-[15px]'>{selectedItem.name}</Box>;
    };

  const onSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setSearchValue(value);
    if (options?.length) {
      setSelectOptions(getFilterOption(options, value));
    }
  };
  //#endregion Handle Function

  // Remove invalid attribute
  delete restProps.menuClassName;
  delete restProps.renderOptionItem;
  delete restProps.topComponent;
  delete restProps.bottomComponent;
  delete restProps.helperText;

  return (
    <>
      <Box className={cx('input-select-container', className)}>
        {(
          <Select
            disabled={!selectOptions}
            value={value}
            onChange={props.onChange}
            renderValue={() => {
              if (value !== undefined) {
                let selectedItem: any = null;
                if (Array.isArray(value)) {
                  selectedItem = [];
                  for (let i in value) {
                    let matchedOption = props.options?.find((item) => item.id === value[i]);
                    if (matchedOption) selectedItem.push(matchedOption);
                  }
                } else {
                  selectedItem = props.options?.find((item) => item.id === value);
                }

                return selectedItem ? (
                  renderSelectedItem(selectedItem)
                ) : (
                  <Box className='px-[15px] text-[#b0b4bd] text-[14px]'>{placeholder ?? label}</Box>
                );
              }
              return (
                <Typography className='flex px-[15px] flex-1 select-none text-[14px]/[16.8px] text-[#A2A7B4] font-normal'>
                  {placeholder ?? label ?? ''}
                </Typography>
              );
            }}
            defaultValue=''
            inputProps={{ 'aria-label': 'Without label' }}
            displayEmpty={true}
            IconComponent={
              isHideIcon ? 'span' : (props) => <ArrowDownIcon {...props} className={cx('dropdown-select-icon')} />
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              id: 'select-menu',
              sx: { minWidth: 220 },
              className: cx('base-select-menu-item', props?.menuClassName),
            }}
            {...restProps}
          >
            {label && (
              <Typography className='mb-[16px] text-[14px]/[16.8px] text-[#3A3F51] font-medium'>{label}</Typography>
            )}
            {isSearchEnable && (
              <TextField
                onKeyDown={(e) => e.stopPropagation()}
                placeholder={t('baseSelect.search')}
                className='mb-[16px]'
                onChange={onSearch}
                defaultValue={searchValue}
                InputProps={{ startAdornment: <SearchIcon className='w-[16px]' /> }}
              />
            )}
            {props.topComponent && props.topComponent()}

            <MenuItem className='hidden' value=''></MenuItem>
            {selectOptions && renderOptionItem
              ? selectOptions.map((option: T, index: number) => {
                  return (
                    <MenuItem key={index} value={option.id}>
                      {renderOptionItem(option, index)}
                    </MenuItem>
                  );
                })
              : restProps.children}
            {props.bottomComponent && props.bottomComponent()}
          </Select>
        )}
      </Box>
      {props.helperText && (
        <Box>
          <FormHelperText error={true}>{props.helperText}</FormHelperText>
        </Box>
      )}
    </>
  );
};

export default memo(BaseSelect) as typeof BaseSelect;
