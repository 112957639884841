import { StorageEnum } from '../enum/base';
import { WebSocketEventEnum, WebSocketTypeEnum } from '../enum/websocket';
import { IPayloadWSS } from '../interface/websocket';

export const login = async (webSocket: WebSocket) => {
  const accountId = localStorage.getItem(StorageEnum.ACCOUNT_ID);
  const deviceId = localStorage.getItem(StorageEnum.DEVICE_ID);
  if (!accountId || !deviceId) {
    return;
  }
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.USE_APP,
    data: {
      event: WebSocketEventEnum.LOGIN,
      accountId: +accountId,
      deviceId: deviceId,
    },
  };
  webSocket.send(JSON.stringify(payload));
};

export const joinConversation = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.JOIN_CONVERSATION,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const leaveConversation = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.LEAVE_CONVERSATION,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const joinListConversation = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.JOIN_LIST_CONVERSATION,
    },
  };

  websocket.send(JSON.stringify(payload));
};

export const leaveListConversation = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.LEAVE_LIST_CONVERSATION,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const typing = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.TYPING,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const stopTyping = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.CHAT,
    data: {
      event: WebSocketEventEnum.STOP_TYPING,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};
