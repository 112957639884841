import { Box, TextField } from '@mui/material';
import { ArrowDownIcon } from '~/assets/icons';
import { forwardRef, InputHTMLAttributes, memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './BaseDatePicker.module.scss';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';

const cx = classNames.bind(styles);

export type BaseDatePickerProps = {
  value?: Date | string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (date: Date | null) => void;
};

type TextFieldInputProps = InputHTMLAttributes<HTMLInputElement>;

export const BaseDatePicker = (props: BaseDatePickerProps) => {
  //#region Destructuring Props
  const { value, placeholder, error, helperText, onChange } = props;
  //#endregion Destructuring Props

  //#region Declare State
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [dateText, setDateText] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (value) {
      const date = dayjs(value).toDate();
      setSelectedDate(date);
      setDateText(dayjs(date).format('MMM D'));
    }
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = (date: Date | null) => {
    setSelectedDate(date);
    setDateText(dayjs(date).format('MMM D'));
    if (onChange) onChange(date); 
  };
  //#endregion Handle Function

  const TextFieldInput = forwardRef<HTMLInputElement, TextFieldInputProps>(({ onClick }, ref) => (
    <TextField
      ref={ref}
      inputProps={{ readOnly: true }}
      InputProps={{
        endAdornment: <ArrowDownIcon className='w-[13px]' />,
      }}
      onClick={onClick}
      value={dateText}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      sx={{
        '& .MuiInputBase-input': {
          padding: '15px',
        },
      }}
    />
  ));

  return (
    <Box className={cx('base-date-picker-container')}>
      <Box className={cx('base-date-picker-menu')}>
        <DatePicker
          selected={selectedDate}
          onChange={handleChange}
          dateFormat='MMM d'
          calendarStartDay={1}
          showPopperArrow={false}
          customInput={
            <TextFieldInput
              value={dateText}
              onClick={() => {}}
              readOnly
            />
          }
          popperPlacement='top-start'
          
        />
      </Box>
    </Box>
  );
};

export default memo(BaseDatePicker);