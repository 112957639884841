import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authActions, selectAccessToken, usetProfile } from '~/thunks/auth/authSlice';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Toolbar,
  Badge,
  MenuItem,
  TextField,
  Menu,
  Typography,
  Button,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import {
  ArrowLeftDoubleIcon,
  CalendarIcon,
  CalendarActiveIcon,
  DashboardIcon,
  DashboardActiveIcon,
  EmployeeIcon,
  EmployeeActiveIcon,
  CrmIcon,
  CrmActiveIcon,
  FormIcon,
  FormActiveIcon,
  InventoryIcon,
  InventoryActiveIcon,
  ProposalsIcon,
  ProposalsActiveIcon,
  OrdersIcon,
  OrdersActiveIcon,
  ReportsIcon,
  ReportsActiveIcon,
  SettingIcon,
  SettingActiveIcon,
  TaskIcon,
  TaskActiveIcon,
  NotificationIcon,
  MessageIcon,
  ArrowDownMenuIcon,
  LogOutIcon,
  UserIcon,
  SearchIcon,
  LogOutSidebarIcon,
  ApertureIcon,
  ApertureActiveIcon
} from '~/assets/icons';

import { useTranslation } from 'react-i18next';
import { BaseImage } from '~/components/common';
import logo from '~/assets/images/logo.svg';
import styles from './Main.module.scss';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import { useAppDispatch } from '~/utils/redux/hooks';
import { StorageEnum } from '~/utils/enum/base';
import authApi from '~/apis/auth';
import { IUserProfile } from '~/utils/interface/account';
// import { StorageEnum } from '~/utils/enum';

type AppBarProps = MuiAppBarProps & {
  open?: boolean;
};

const sidebarList = [
  {
    name: 'dashboard',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
    url: '/admin/dashboard',
  },
  {
    name: 'employee',
    icon: <EmployeeIcon />,
    activeIcon: <EmployeeActiveIcon />,
    url: '/admin/employee',
  },
  {
    name: 'schedule',
    icon: <CalendarIcon />,
    activeIcon: <CalendarActiveIcon />,
    url: '/admin/schedule',
  },
  {
    name: 'integration',
    icon: <ApertureIcon />,
    activeIcon: <ApertureActiveIcon />,
    url: '/admin/#',
  },
  {
    name: 'crm',
    icon: <CrmIcon />,
    activeIcon: <CrmActiveIcon />,
    url: '/admin/#',
  },
  {
    name: 'accounting',
    icon: <TaskIcon />,
    activeIcon: <TaskActiveIcon />,
    url: '/admin/#',
  },
  {
    name: 'estimate',
    icon: <FormIcon />,
    activeIcon: <FormActiveIcon />,
    url: '/admin/#',
  },
 
  {
    name: 'reports',
    icon: <ReportsIcon />,
    activeIcon: <ReportsActiveIcon />,
    url: '/admin/#',
  },
  {
    name: 'preference',
    icon: <InventoryIcon />,
    activeIcon: <InventoryActiveIcon />,
    url: '/admin/#',
  },
];

const cx = classNames.bind(styles);

const drawerWidth = 260;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const deafultValue = { id: '1', name: 'Select Franchise 1' };

export default function MiniDrawer() {
  const { t } = useTranslation(['admin/layout']);


  const userInfo:IUserProfile| undefined = useSelector(usetProfile);


  const dispatch = useAppDispatch();
  const location = useLocation();

  const [open, setOpen] = useState<boolean | undefined>(true);
  const activeFranchise = false;
  const [activeScreen, setActiveScreen] = useState<string>('dashboard');
  const [menuAnchorElement, setmenuAnchorElement] = useState<null | HTMLElement>(null);
  // const [isLoggedIn] = useState<boolean>(!!localStorage.getItem(StorageEnum.ACCESS_TOKEN));
  const isLoggedIn = true;
  //const [isLoggedIn] = [!!localStorage.getItem(StorageEnum.ACCESS_TOKEN)];
  const isMenuOpen = Boolean(menuAnchorElement);

  const navigate = useNavigate();

  useEffect(() => {
    const matchedScreen = sidebarList.find((screen) => location.pathname.indexOf(screen.url) > -1);
    if (matchedScreen) setActiveScreen(matchedScreen.name);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setmenuAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setmenuAnchorElement(null);
  };

  const hanleNavigateMenuAnchor = (url: string) => {
    handleClose();
    navigate(url);
  };

  const handleNavigateDrawer = (url: string) => {
    navigate(url);
  };
  
  
  const getInfo = async ()=>{
    if(userInfo) {
      return
    };

    try {
      let info = await authApi.authMe();
      if(!info.data.data)
      {
        return;
      }
      dispatch(authActions.setUserProfile(info.data.data));
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    if(isLoggedIn){
      getInfo();
    }
  },[])

  return isLoggedIn ? (
    <Box className={cx('layout-main')}>
     
     <AppBar position='fixed' open={open} color='default'>
        <Box className={cx('flex justify-between px-[40px] gap-[16px]', { 'pl-0': !open })}>
          <Toolbar className='p-0'>
            <IconButton
              color='inherit'
              onClick={() => setOpen(true)}
              edge='start'
              className={cx('p-0 w-[40px] h-[40px]', { hidden: open, ' ml-[20px] mr-[40px]': !open })}
            >
              <ArrowLeftDoubleIcon className='w-[11px]' />
            </IconButton>
            <TextField
              className={cx('search-input', 'hidden lg:block')}
              placeholder={t('header.search')}
              InputProps={{
                startAdornment: <SearchIcon className='w-[16px]' />,
              }}
            />
          </Toolbar>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} className={cx('right-toolbar')}>
            <IconButton size='large' color='inherit' className={cx('badge-button')}>
              <Badge badgeContent={17} color='error'>
                <MessageIcon />
              </Badge>
            </IconButton>
            <IconButton size='large' color='inherit' className={cx('badge-button')}>
              <Badge badgeContent={4} color='info' className={cx('notification-badge')}>
                <NotificationIcon />
              </Badge>
            </IconButton>
            <Box className='flex flex-row gap-[16px] h-[38px]'>
              <Box className='text-right justify-between flex-col hidden sm:flex'>
                <Box className='text-[#222732] font-semibold'>{userInfo ? userInfo.username : ""}</Box>
                <Box className='text-[#9AA4BA]'>{userInfo ? userInfo.email : ""}</Box>
              </Box>
              <BaseImage src={logo} className='h-[38px] w-[38px]'></BaseImage>
              <Box className='flex items-center'>
                <IconButton onClick={handleClick} className='flex justify-center w-[20px] h-[20px] !p-0'>
                  <ArrowDownMenuIcon className='w-[13px]' />
                </IconButton>

                <Menu id='main-menu' anchorEl={menuAnchorElement} open={isMenuOpen} onClose={handleClose}>
                  <Box className='flex flex-row gap-[12px] h-[38px]'>
                    <BaseImage src={logo} className='h-[38px] w-[38px]'></BaseImage>
                    <Box className='flex justify-between flex-col'>
                      <Box className='text-[#222732] font-semibold'>{userInfo ? userInfo.username : ""}</Box>
                      <Box className='text-[#9AA4BA]'>{userInfo ? userInfo.email : ""}</Box>
                    </Box>
                  </Box>
                  <Divider className='!mt-[16px] !mb-[20px]' />
                  <MenuItem onClick={() => hanleNavigateMenuAnchor(adminRouteAbsolute.profile)}>
                    <UserIcon className='h-[16px]' />
                    {t('header.editProfile')}
                  </MenuItem>
                  <MenuItem onClick={() => hanleNavigateMenuAnchor(adminRouteAbsolute.accountSetting)}>
                    <SettingIcon className='h-[16px]' />
                    {t('header.accountSetting')}
                  </MenuItem>
                  <MenuItem onClick={() =>  {
                      dispatch(authActions.handleLogout());
                      hanleNavigateMenuAnchor(adminRouteAbsolute.login)
                  }}>
                    <LogOutIcon className='h-[16px]' />
                    {t('header.logOut')}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
      </AppBar>
      <Drawer variant='permanent' open={open} className='sidebar' sx={{ '& > :first-of-type': {backgroundColor: '#FFFFFF',}, }}>
        <DrawerHeader className='min-h-[82px] flex justify-between px-[24px]'>
          <BaseImage src={logo}  />
        </DrawerHeader>
        <Divider className='!border-[#E7EDF3]'/>
        <List className='flex flex-col justify-between h-full'>
          <Box>
            {sidebarList.map((item, index) => (
              <ListItem
                key={index}
                className={cx({ active: activeScreen === item.name })}
                disablePadding
                onClick={() => handleNavigateDrawer(item.url)}
                sx={{
                  color: '#3E5564 !important',
                  '&.active': 
                  {
                    // background: 'linear-gradient(90deg, rgba(243, 165, 37, 0.1) 0%, rgba(243, 165, 37, 0.05) 100%) !important',
                    color: '#FD550C !important',
                    borderRight: '3px solid #FD550C !important',
                  },
                  '&:hover': {
                    background: '#F29A4D', 
                  },
                }}
              >
                <ListItemButton>
                  <ListItemIcon>{activeScreen === item.name ? item.activeIcon : item.icon}</ListItemIcon>
                  <ListItemText primary={t(`sidebar.${item.name}`)} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Box>
              <Typography className='!text-[#8F9AA4] text-center ' sx={{ opacity: open ? 1 : 0 }}>© Don’s Lawn - 2024</Typography>
          </Box>
        </List>
      </Drawer>
      <Box component='main' className={cx('main', 'bg-[#F1F4F6]')}>
        <Outlet />
      </Box>
    </Box>
  
  ) : (
    <Navigate to={adminRouteAbsolute.login} />
  );
}
