import { SearchFields } from '../types/account';


export const PERFORMANCE: Array<{ id: string; name: string }> = [
  { id: 'High', name: 'High' },
  { id: 'Medium', name: 'Medium' },
  { id: 'Low', name: 'Low' },
];

export const GENDER: Array<{ id: string; name: string }> = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'other', name: 'Other' },
];


export const DEPARTMENT: Array<{ id: string; name: string }> = [
  { id: 'contents', name: 'Contents' },
  { id: 'emergency', name: 'Emergency' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'reconstruction', name: 'Reconstruction' },
  { id: 'roofing_exterior', name: 'Roofing/Exterior ' },
];

export const SEARCH_FIELDS: SearchFields = ['staff', 'technician', 'department', 'address'];

export const SORT_FIELDS = ['username', 'profile.employeeID', 'email', 'role', 'profile.joinDate', 'profile.performance'];
export const ROLE_MAPPING = {
  staff: '00002',
  technician: '00003',
};

export const ROLE: Array<{ id: string; name: string }> = [
  { id: '00001', name: 'Administrator' },
  { id: '00002', name: 'Technician' },
  { id: '00003', name: 'Manager' },
  // Add other roles as needed
];