import { IApiBaseResponse } from "~/utils/interface/common";
import axiosClient from "./axiosClient";
import { IResponseGetProductTypes } from "~/utils/interface/product";


const productApi = {
    getProductTypes() {
        return axiosClient.get<IApiBaseResponse<IResponseGetProductTypes>>('product-types');
      },
}

export default productApi;