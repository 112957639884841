import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from '~/App';
import store from '~/utils/redux/store';
import { GlobalStyles } from './components/common';
import { LoadingProvider, WebSocketProvider } from './context';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import reportWebVitals from './reportWebVitals';
// import './thirdParties/i18n';
import { appTheme } from './theme';
import { I18nextProvider } from 'react-i18next';
import i18n from './thirdParties/i18n';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <GlobalStyles>
            <WebSocketProvider>
              <LoadingProvider>
                <I18nextProvider i18n={i18n}>
                  <App />
                </I18nextProvider>
              </LoadingProvider>
            </WebSocketProvider>
          </GlobalStyles>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
