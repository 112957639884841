import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';
import styles from './BaseRow.module.scss';
import classNames from 'classnames/bind';
type BaseRowProps = BoxProps & {};

const cx = classNames.bind(styles);

const BaseRow = (props: BaseRowProps) => {
  const { ...restProps } = props;
  return (
    <Box {...restProps} className={`${props.className ? props.className : ''} ${cx('base-row-container')}`}>
      {props.children}
    </Box>
  );
};

export default memo(BaseRow);
