import React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

type BaseHeaderProps = BoxProps & {
  title: string
}

const BaseHeaderTitle = (props: BaseHeaderProps) => {
  const {title, ...restProps} = props
  return (
    <Box className="flex items-center flex-1" {...restProps}>
        <Typography className="text-[32px]/[38.4px] font-normal">{title}</Typography>
    </Box>
  )
}

export default BaseHeaderTitle
