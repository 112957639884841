import {
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { CloseIcon } from '~/assets/icons';
import { useFormik } from 'formik';
import { IEmployee } from '~/utils/interface/employee';
import { DIALOG_MODE_CREATE, DIALOG_MODE_EDIT } from '~/utils/constants/common';
import { handleApiError, notify , cast} from '~/utils/helper';
import { BaseRow, BaseSelect, BaseUploadFile, BaseDatePicker } from '~/components/common';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '~/utils/types/common';
import { ROLE, DEPARTMENT, ROLE_MAPPING } from '~/utils/constants/employee';
import { handleCreateEmployee, handleEditEmployee } from '~/thunks/employee/employeeThunk';
import { useAppDispatch, useAppSelector } from '~/utils/redux/hooks';
import { employeeActions } from '~/thunks/employee/employeeSlice';
import dayjs from 'dayjs';
import location from '../../../utils/constants/location.json';
import { PlusIcon, SearchIcon, SaveIcon } from '~/assets/icons';
import classNames from 'classnames/bind';
import styleColor from '~/assets/icons/icon.module.scss';
import { BaseHeaderTitle, SelectFilter, SelectSort } from '~/components/common';
import DrawerEmployee from '~/components/employees/Drawer/DrawerEmployee';
import { useNavigate } from 'react-router-dom';
import { handleGetEmployee, handleGetEmployees } from '~/thunks/employee/employeeThunk';
import { useParams } from 'react-router-dom';
import { use } from 'i18next';
const cxColor = classNames.bind(styleColor);

type EditEmployeeDialogProps = {
  open: boolean;
  // setOpenDrawer?: (open: boolean) => void;
  mode?: string;
  title?: string;
  children?: React.ReactNode;
};

const initialValues: IEmployee = {
  role: '00003',
  email: '',
  avatar: undefined,
  address: '',
  phoneNumber: '',
  joinDate: undefined,
  postalCode: '',
  profile: {
    fullName: '',
    department: ''
  }
};

const EditEmployeeDialog = (props: EditEmployeeDialogProps) => {
  const { t } = useTranslation(['admin/account']);
  const { mode = DIALOG_MODE_CREATE } = props;
  const dispatch = useAppDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const navigator = useNavigate();
  const { employeeId } = useParams<{ employeeId: string }>();
  const handleSetOpen = (open: boolean, needRefreshData?: boolean) => {
    if (mode === DIALOG_MODE_CREATE) {
      form.resetForm();
    }
    if (mode === DIALOG_MODE_EDIT) {
      form.resetForm();
    }
    // if (props.setOpen) {
    //   props.setOpen(open, needRefreshData);
    // }
    // if (props.setOpenDrawer) {
    //   props.setOpenDrawer(open);
    // }
  };

  const handleCancel = () => {
    handleSetOpen(false);
    navigator('/admin/employee/');
  };

  const form = useFormik({
    validateOnChange: false,
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      console.log('values', values)
      const formattedData: any = {
        username: 'none',
        email: values.email,
        role: values.role,
        profile: {
          phoneNumber: values.phoneNumber,
          address: values.address,
          joinDate: values.joinDate,
          fullName: values.profile?.fullName,
          department: values.profile?.department,
          jobsCompleted: values.profile?.jobsCompleted,
          hoursWorked: values.profile?.hoursWorked,
          productionValue: values.profile?.productionValue,
        },
      };
      if (values.avatar && typeof values.avatar === 'object' && 'type' in values.avatar) {
        formattedData.file = values.avatar;
      }
      if (mode === DIALOG_MODE_CREATE) {
        try {
          await dispatch(handleCreateEmployee(formattedData)).unwrap();
          dispatch(employeeActions.changeNeedRefreshData(true));
          notify('success', t('createSuccess', { ns: 'admin/common' }));
          handleCancel();
        } catch (e: unknown) {
          handleApiError(e, setFieldError);
        }
        return;
      }
      if (mode === DIALOG_MODE_EDIT && employeeId) {
        try {
          await dispatch(handleEditEmployee({ id: employeeId, params: formattedData })).unwrap();
          notify('success', t('editSuccess', { ns: 'admin/common' }));
        } catch (e: unknown) {
          handleApiError(e, setFieldError);
        }
        return;
      }
    },
  });

  useEffect(() => {
    if (mode === DIALOG_MODE_EDIT && employeeId) {
      (async () => {
        try {
          const data = await dispatch(handleGetEmployee(employeeId)).unwrap();
          console.log('data', data);
          form.setFieldValue('joinDate', data?.profile?.joinDate);
          form.setFieldValue('email', data?.email);
          form.setFieldValue('phoneNumber', data?.profile?.phoneNumber);
          form.setFieldValue('address', data?.profile?.address);
          form.setFieldValue('avatar', data?.profile?.avatar?.url);
          form.setFieldValue('state', data?.profile?.state);
          form.setFieldValue('postalCode', data?.profile?.postalCode);
          form.setFieldValue('profile.fullName', data?.profile?.fullName);
          form.setFieldValue('profile.department', data?.profile?.department);
          form.setFieldValue('profile.jobsCompleted', data?.profile?.jobsCompleted);
          form.setFieldValue('profile.hoursWorked', data?.profile?.hoursWorked);
          form.setFieldValue('profile.productionValue', data?.profile?.productionValue);
          form.setFieldValue('profile.joinDate', data?.profile?.joinDate);
          form.setFieldValue('role', data?.role);
        } catch (e: unknown) {
          handleApiError(e);
        }
      })();
    }
  }, [mode, employeeId]);


  const handleSetOpenDrawer = async (open: boolean) => {
    setIsDrawerOpen(open);
  };

  return (
    <Box className='w-full text-[#200E01]'>
      {/* <Box className='!text-xl !font-light'>
        {mode === DIALOG_MODE_CREATE ? t('addNewEmployee') : t('editEmployee')}
        <IconButton onClick={() => handleSetOpen(false)} className='absolute right-[10px] top-[10px]'>
          <CloseIcon className='w-[10px] h-[10px]' />
        </IconButton>
      </Box> */}
      <Box className='flex items-center justify-between mb-5'>
        <BaseHeaderTitle title={mode === DIALOG_MODE_CREATE ? t('addNewEmployee') : t('editEmployee')} />
        <Button
          color='primary'
          variant='contained'
          onClick={()=>{
            handleSetOpenDrawer(true);
          }}
          className='mr-2'
          startIcon={<PlusIcon className={cxColor('w-[10px] h-[10px]', 'icon-fill-ffffff')} />}
        >
          {t('invite', { ns: 'admin/common' })}
        </Button>

      </Box>

      <Box component='form' className='flex gap-10 w-full'>
        <Box className='bg-white rounded-[8px] p-[30px] flex-1'>
          <Typography className='text-[#200E01] mb-[30px] text-[20px]'>Personal Info</Typography>
          <BaseRow className='gap-[20px] mb-[20px]'>
            <Box className='w-full flex justify-between gap-[20px]'>

              <Box className='grow'>
                <InputLabel>{t('fullName')}</InputLabel>
                <TextField
                  name='profile.fullName'
                  placeholder={t('fullName')}
                  value={form.values?.profile?.fullName}
                  onChange={form.handleChange}
                  error={Boolean(form.errors?.profile)}
                  helperText={form.errors?.profile}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '15px',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className='w-full'>
              <InputLabel>{t('email')}</InputLabel>
              <TextField
                name='email'
                placeholder={t('email')}
                value={form.values?.email}
                onChange={form.handleChange}
                error={Boolean(form.errors?.email)}
                helperText={form.errors?.email}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
          </BaseRow>
          <BaseRow className='gap-[20px] mb-[20px]'>
            <Box className='w-full'>
              <InputLabel>{t('Phone')}</InputLabel>
              <TextField
                name='phoneNumber'
                placeholder={t('phoneNumber')}
                value={form.values?.phoneNumber}
                onChange={form.handleChange}
                error={Boolean(form.errors?.phoneNumber)}
                helperText={form.errors?.phoneNumber}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
            <Box className='w-full'>
              <InputLabel>{t('address')}</InputLabel>
              <TextField
                name='address'
                placeholder={t('address')}
                value={form.values?.address}
                onChange={form.handleChange}
                error={Boolean(form.errors?.address)}
                helperText={form.errors?.address}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
          </BaseRow>
          <BaseRow className=''>
            <Box className='gap-[20px] mb-[20px] w-full'>
              <InputLabel>{t('Profile Image')}</InputLabel>
              <BaseUploadFile
                uploadedFiles={typeof form.values?.avatar === 'string' ? [{ url: form.values?.avatar }] : undefined}
                onChange={(files: Array<UploadFile>) => {
                  form.setFieldValue('avatar', files[0]?.file ?? '');
                }}
              />
            </Box>
          </BaseRow>
        </Box>

        <Box className='bg-white rounded-[8px] p-[30px] flex-1'>
          <Typography className='text-[#200E01] mb-[30px] text-[20px]'>Job Detail</Typography>
          <BaseRow className='gap-[20px] mb-[20px]'>
            <Box className='w-full'>
              <InputLabel>{t('Department')}</InputLabel>
              <BaseSelect
                name='profile.department'
                placeholder={t('department')}
                value={form.values.profile?.department}
                options={DEPARTMENT}
                onChange={form.handleChange}
                error={Boolean(form.errors?.profile)}
                helperText={form.errors?.profile}
              ></BaseSelect>
            </Box>
            <Box className='w-full'>
              <InputLabel>{t('Roles')}</InputLabel>
              <BaseSelect
                name='role'
                placeholder={t('role')}
                value={form.values.role}
                options={ROLE}
                onChange={form.handleChange}
                error={Boolean(form.errors?.role)}
                helperText={form.errors?.role}
              ></BaseSelect>
            </Box>
          </BaseRow>
          <BaseRow className='gap-[20px] mb-[20px]'>
            <Box className='w-full'>
              <InputLabel>{t('date')}</InputLabel>
              <BaseDatePicker
                placeholder={t('date')}
                value={form.values.joinDate}
                onChange={(date: any) => {
                  if (date) {
                    const selectedDate = dayjs(date).format('YYYY-MM-DD');
                    form.setFieldValue('joinDate', selectedDate);
                  }
                }}
                error={Boolean(form.errors.joinDate)}
                helperText={form.errors.joinDate}
              />
            </Box>
            <Box className='w-full'>
              <InputLabel>{t('jobsCompleted')}</InputLabel>
              <TextField
                name='profile.jobsCompleted'
                placeholder={t('jobsCompleted')}
                value={form.values?.profile?.jobsCompleted}
                onChange={form.handleChange}
                error={Boolean(form.errors?.profile)}
                helperText={form.errors?.profile}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
          </BaseRow>
          <BaseRow className='gap-[20px] mb-[20px]'>
            <Box className='w-full'>
              <InputLabel>{t('hoursWorked')}</InputLabel>
              <TextField
                name='profile.hoursWorked'
                placeholder={t('hoursWorked')}
                value={form.values?.profile?.hoursWorked}
                onChange={form.handleChange}
                error={Boolean(form.errors?.profile)}
                helperText={form.errors?.profile}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
            <Box className='w-full'>
              <InputLabel>{t('productionValue')}</InputLabel>
              <TextField
                name='profile.productionValue'
                placeholder={t('productionValue')}
                value={form.values?.profile?.productionValue}
                onChange={form.handleChange}
                error={Boolean(form.errors?.profile)}
                helperText={form.errors?.profile}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '15px',
                  },
                }}
              />
            </Box>
          </BaseRow>
        </Box>
      </Box>
      <Box className="mt-6">
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            form.handleSubmit();
          }}
          className='mr-2'
          startIcon={
            mode !== DIALOG_MODE_CREATE ? <SaveIcon className={cxColor('w-[10px] h-[10px]')} /> :
          <PlusIcon className={cxColor('w-[10px] h-[10px]', 'icon-fill-ffffff')} />
        }
        >
          {mode === DIALOG_MODE_CREATE ? t('addEmployee') : t('Save Info')}
        </Button>
        <Button color='secondary' className='text-[#645B55] bg-[#FFFFFF] border' variant='contained' onClick={() => handleCancel()}>
          {t('cancel', { ns: 'admin/common' })}
        </Button>
      </Box>
      <DrawerEmployee
            open={isDrawerOpen}
            setOpen={handleSetOpenDrawer}
            setOpenEdit={handleSetOpen}
            // data={editData}
          />
    </Box>
  );
};

export default memo(EditEmployeeDialog);