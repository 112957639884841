import { Box, Button, Checkbox, css, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material';
import styles from '../Auth.module.scss';
import classNames from 'classnames/bind';
import { ILoginPayload } from '~/apis/auth';
import { images } from '~/assets';
import { useFormik } from 'formik';
import { useAppDispatch } from '~/utils/redux/hooks';
import { handleRegester, handleResetPass } from '~/thunks/auth/authThunk';
import { IBodyLogin, IBodyResetPass, IBodySignUp } from '~/utils/interface/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleApiError } from '~/utils/helper/common';
import { adminRouteAbsolute } from '~/utils/routes/admin';
import AdminLogin from '~/layouts/admin/login/AdminLogin';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { checkInputError, emailInputErrors, passwordInputErrors, usernameInputErrors } from '~/utils/helper/auth';
import { StorageEnum } from '~/utils/enum/base';

const cx = classNames.bind(styles);

type Props = {};
const initialValues: IBodyResetPass = {
  code: '',
  password: '',
  confirmPassword: ''
};



const ResetPassComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const code:any = searchParams.get("code");

  const [step,setStep] = useState(1);

  const [formError, setFormError] = useState<IBodyResetPass>({
    code: '',
    password: '',
    confirmPassword: ''
  })

  const form:any = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      try {
        let valueLogin:IBodyResetPass = {
          code: code,
          password: values.password,
          confirmPassword: values.confirmPassword
        };

       await dispatch(handleResetPass({body:valueLogin})).unwrap();
        setStep(2);
      } catch (e) {    
        if(e instanceof AxiosError && e?.response?.data?.message)
        {
          let message:string = e?.response?.data?.message;
          let [isError, error] = checkInputError({
            code: '',
            password: '',
            confirmPassword: ''
          },message , "password" );
          
          if(isError){
            return setFormError(error);
          }
        }
        handleApiError(e, setFieldError);
      }
    }
  });

  const onGoHome = () => {
    navigate(`${adminRouteAbsolute.login}`);
  }

  return <div id='signInComponent'>
    <AdminLogin isHideLogo={step==2}>
      {
        step == 1 && <Box className={cx('relative z-10 ')}>
          <Box component='section' className={cx('w-full h-full')}>
            <Box component='form' onSubmit={form.handleSubmit} className={cx('max-w-[500px] w-full px-4 sm:px-0')}>
              <Box>
                <Box className={cx('gap-5')}>
                  
                  <Box className={cx('mt-5')}>
                    <Typography className={cx('!font-medium !text-black !text-[36px] !leading-[43.2px]  font-medium text-center text-[#200E01]')} variant='h2'>
                      Set New Password
                    </Typography>
                    <Typography
                      variant='body1'
                      className={cx('!font-normal text-[16px] !leading-[19.2px] !text-[#3E5564] !mt-2.5  text-center text-[#554A42]')}
                    >
                      Enter the new password you want to create and replace it.
                    </Typography>
                  </Box>
                </Box>
                <Box className={cx('sm:mt-[30px] mt-6')}>
                  <Box className={cx(`input-label`,"mt-5", "input-password")}>
                    <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                      New Password
                    </InputLabel>
                    <TextField
                      className={cx("input-pass",'w-full border-[#E3E7EA]' )}
                      name='password'
                      type={showPassword ? "text" : "password"}
                      placeholder='Enter new password'
                      value={form.values.password}
                      onChange={form.handleChange}
                      error={Boolean(formError.password)}
                      helperText={formError.password}
                      InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              className='mr-[20px]'
                            >
                              {
                                showPassword ? 
                                <img src={images.showPass} alt='' className={cx('w-[12px] h-[12px]')} /> :
                                <img src={images.hidePass} alt='' className={cx('w-[12px] h-[12px]')} />
                              }
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>

                  <Box className={cx(`input-label`,"mt-[25px]", "input-password")}>
                    <InputLabel className={cx('!text-[12px] text-[#323953] !font-normal !leading-[14.4px]')}>
                      Confirm New Password
                    </InputLabel>
                    <TextField
                      className={cx("input-pass",'w-full border-[#E3E7EA]' )}
                      name='confirmPassword'
                      type={showPasswordConfirm ? "text" : "password"}
                      placeholder='Confirm new password'
                      value={form.values.confirmPassword}
                      onChange={form.handleChange}
                      error={Boolean(formError.confirmPassword)}
                      helperText={formError.confirmPassword}
                      InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPasswordConfirm}
                              className='mr-[20px]'
                            >
                              {
                                showPasswordConfirm ? 
                                <img src={images.showPass} alt='' className={cx('w-[12px] h-[12px]')} /> :
                                <img src={images.hidePass} alt='' className={cx('w-[12px] h-[12px]')} />
                              }
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <InputLabel className={cx(`!text-[14px] text-[#8A97A0] !font-normal !leading-[14.4px] m-0  font-[Inter] ${formError.confirmPassword ? "mt-0": "mt-4"}`)}>
                      Must be at least 8 characters
                    </InputLabel>
                  </Box>
                </Box>
              </Box>
              <Box className={cx('mt-[30px]')}>
                <Button
                  type='submit'
                  variant='contained'
                  //color='primary'
                  sx={{
                    background:'#121055',
                    padding:"13px 24px",
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                  }}
                  className={'w-full !rounded-lg h-9 !leading-[16.8px]'}
                >
                  Reset Password
                </Button>

              </Box>
            </Box>
          </Box>
        </Box>
      }
      
      {
        step == 2 && <Box className={cx(' relative z-10 ')}>
          <Box component='section' className={cx('w-full h-full')}>
            <Box component='form' onSubmit={form.handleSubmit} className={cx('max-w-[500px] w-full px-4 sm:px-0')}>
              
            <Box>
                <Box className={cx('gap-5 text-center')}>            
                  <img src={images.star_complate} alt='' className={cx('m-auto')}  /> 
                </Box>
              </Box>
              <Box>
                <Box className={cx('gap-5')}>            
                  <Box className={cx('mt-5 text-center')}>
                    <Typography className={cx('!font-medium !text-black !text-[36px] !leading-[43.2px] text-center text-[#200E01]')} variant='h2'>
                      Successful!
                    </Typography>
                    <Typography
                      variant='body1'
                      className={cx('!font-normal text-[16px] !leading-[19.2px] !text-[#86929D] !mt-2.5 text-center text-[#554A42]')}
                    >
                      You have successfully reset your password.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={cx('mt-[30px] text-center')}>
                <Button
                  type='button'
                  variant='contained'
                  onClick={onGoHome}
                  //color='primary'
                  sx={{
                    background:'#121055',
                    padding:"13px 24px",
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                  }}
                  className={'!rounded-lg h-9 !leading-[16.8px] px-5 py-2.5'}
                >
                  Back to Home
                </Button>

              </Box>
            </Box>
          </Box>
        </Box>
      }
    </AdminLogin>
  </div>;
};

export default ResetPassComponent;
