import classNames from 'classnames/bind';
import styles from './Employee.module.scss';
import { Box, Button, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DIALOG_MODE_CREATE, DIALOG_MODE_EDIT } from '~/utils/constants/common';
import EditEmployeeDialog from '../../../components/employees/EditEmployee/EditEmployeeDialog';
import EmployeeList from '../../../components/employees/EmployeeList/EmployeeList';
import { useAppDispatch, useAppSelector } from '~/utils/redux/hooks';
import { catchError, handleApiError, notify } from '~/utils/helper';
import { SEARCH_FIELDS, SORT_FIELDS } from '~/utils/constants/employee';
import { handleGetEmployee, handleGetEmployees } from '~/thunks/employee/employeeThunk';
import useDebounce from '~/hooks/useDebounce';
import { employeeActions } from '~/thunks/employee/employeeSlice';
import { IEmployee, IPayloadDeleteEmployee } from '~/utils/interface/employee';
import ModalAlertDelete from '~/components/common/modal-alert-delete/ModalAlertDelete';
import DrawerEmployee from '~/components/employees/Drawer/DrawerEmployee';
import employeeApi from '~/apis/employee';
import styleColor from '~/assets/icons/icon.module.scss';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import EmployeeDetail from '~/components/employees/EmployeeDetail';

const cx = classNames.bind(styles);

const mockEmployee = {
  name: 'John Doe',
  role: 'Software Engineer',
  email: 'john.doe@example.com',
  phone: '123-456-7890',
  address: '123 Main St, Anytown, USA',
  stars: '4.5',
  tips: '$200',
  production: '$5000',
  bonuses: '$1000',
  sales: '$3000',
  department: 'Engineering',
  hoursWorked: 160,
  productionValue: 5000,
  dateJoining: '2020-01-15',
  jobsCompleted: 50,
  emergencyContact: {
    fatherName: 'Robert Doe',
    motherName: 'Jane Doe',
    dateOfBirth: '1990-05-20',
    hobbies: 'Reading, Hiking',
    likes: 'Pizza, Movies',
    dislikes: 'Spicy Food',
    city: 'Anytown',
    state: 'California',
    religion: 'Christianity',
    bloodGroup: 'O+',
  },
};

const Employee = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const { t } = useTranslation(['admin/account', 'admin/component']);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [editDialogMode, setEditDialogMode] = useState<string>('');
  const dispatch = useAppDispatch();
  const searchInputDebounce = useDebounce<string>(searchInput);
  const [editData, setEditData] = useState<IEmployee>();
  const { filter, needRefreshData, selectedIds, openAlertDelete, menuSelectedId } = useAppSelector(
    (state) => state.employee
  );
  const navigate = useNavigate();
  const handleSetOpen = async (open: boolean, _needRefreshData?: boolean) => {
    setOpenEditDialog(open);
  };

  const handleSetOpenDrawer = async (open: boolean) => {
    setIsDrawerOpen(open);
  };


  const handleOpenEdit = async (id: string) => {
    const response = await dispatch(handleGetEmployee(id)).unwrap();
    setEditData(response);
    setEditDialogMode(DIALOG_MODE_EDIT);
    setOpenEditDialog(true);
    try {
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleOpenDrawer = async (id: string) => {
    try {
      const response = await dispatch(handleGetEmployee(id)).unwrap();
      setEditData(response);
      setIsDrawerOpen(true);
    } catch (e) {
      handleApiError(e);
    }
  };

  const openAlertDeleteModal = useCallback(() => {
    dispatch(employeeActions.changeOpenAlertDelete(true));
  }, [dispatch]);

  const closeAlertDeleteModal = useCallback(() => {
    dispatch(employeeActions.changeMenuSelectId(undefined));
    dispatch(employeeActions.changeOpenAlertDelete(false));
  }, [dispatch]);

  const onDeleteSelectedJobs = useCallback(async () => {
    let payloaddeleteEmployees: IPayloadDeleteEmployee =
      menuSelectedId !== undefined ? { ids: [menuSelectedId] } : { ids: [...selectedIds] };
    try {
      await employeeApi.deleteEmployee(payloaddeleteEmployees);
      notify('success', t('deleteSuccessMessage'));
      dispatch(employeeActions.changeNeedRefreshData(true));
      dispatch(employeeActions.changeOpenAlertDelete(false));
    } catch (e: unknown) {
      catchError(e, (message) => {
        notify('error', message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, menuSelectedId, selectedIds, t]);

  useEffect(() => {
    if (dispatch && typeof searchInputDebounce !== 'undefined') {
      dispatch(
        employeeActions.setFilter({
          textSearch: searchInputDebounce,
          // searchFields: ['name'],
          page: 1,
        })
      );
    }
  }, [searchInputDebounce, dispatch]);

  useEffect(() => {
    dispatch(handleGetEmployees(filter))
      .unwrap()
      .catch((e) => {
        handleApiError(e);
      });
  }, [dispatch, filter, needRefreshData]);

  return (
    <Box className=''>

      <Box className=''>
        <Routes>
          <Route path="add-employee" element={<EditEmployeeDialog
            open={true}
            mode={DIALOG_MODE_CREATE}
          />} >
          </Route>
          <Route path=":employeeId" element={<EmployeeDetail />} ></Route>
          <Route path="" element={<EmployeeList  />} ></Route>
          <Route path="edit-employee/:employeeId" element={<EditEmployeeDialog
            open={true}
            mode={DIALOG_MODE_EDIT}
          />} ></Route>
        </Routes>
      </Box>
      <ModalAlertDelete
        open={openAlertDelete}
        description={t('alertDescription')}
        onCancel={closeAlertDeleteModal}
        onConfirm={onDeleteSelectedJobs}
      />
    </Box>
  );
};

export default Employee;
