import { urlApiAuth } from '~/utils/constants/apis';
import axiosClient from './axiosClient';
import { IBodyForgetpass,IBodySignUp, IBodyLogin, IResponseLogin, IBodyResetPass, IBodyInviteSignUp } from '~/utils/interface/auth';
import { IBaseResponse } from '~/utils/interface/base';
import { IUserProfile } from '~/utils/interface/account';

const authApi = {
  login(payload: { body: IBodyLogin }) {
    const { body } = payload;
    const url = `${urlApiAuth.login}`;
    return axiosClient.post<IBaseResponse<IResponseLogin>>(url, body);
  },

  forgetPass(payload: { body: IBodyForgetpass }) {
    const { body } = payload;
    const url = `${urlApiAuth.forgetPass}`;
    return axiosClient.post<IBaseResponse<IResponseLogin>>(url, body);
  },

  signup(payload: { body: IBodySignUp }) {
    const { body } = payload;
    const url = `${urlApiAuth.signup}`;
    return axiosClient.post<IBaseResponse<IResponseLogin>>(url, body);
  },

  resetPass(payload: { body: IBodyResetPass }) {
    const { body } = payload;
    const url = `${urlApiAuth.resetPass}`;
    return axiosClient.post<IBaseResponse<IResponseLogin>>(url, body);
  },

  authMe() {
    const url = `${urlApiAuth.info}`;
    return axiosClient.get<IBaseResponse<IUserProfile>>(url);
  },

  signupWithInvite(payload: { body: IBodyInviteSignUp }) {
    const { body } = payload;
    const url = `${urlApiAuth.signupWithInvite}`;
    return axiosClient.post<IBaseResponse<any>>(url, body);
  },

  // authMe() {
  //   const url = `${urlApiAuth.info}`;
  //   return axiosClient.get(url);
  // },
};

export default authApi;

export interface ILoginPayload {
  email: string;
  password: string;
}
