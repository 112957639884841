import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { handleGetEmployee, handleGetEmployees } from '~/thunks/employee/employeeThunk';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IEmployee, IPayloadDeleteEmployee } from '~/utils/interface/employee';
import { catchError, handleApiError, notify } from '~/utils/helper';
import images from '~/assets/images';
import {useNavigate} from 'react-router-dom';

interface InfoCardProps {
  icon?: string;
  topText: string;
  bottomText?: string | number | null | undefined;
}
const InfoCard: React.FC<InfoCardProps> = ({
  icon = images.userDetail.emailIcon,
  topText = '',
  bottomText,
}) => {
  return (
    <div className="flex items-center space-x-4  ">
      <Avatar className="bg-gray-100">
        <img src={icon} alt='icon' />
      </Avatar>
      <div>
        <Typography variant="caption" className="text-[#726359] text-[12jpx]">
          {topText}
        </Typography>
        <Typography variant="subtitle1" className="textt-[#200E01] text-[14px]">
          {bottomText ?? 'N/A'}
        </Typography>
      </div>
    </div>
  );
};



const EmployeeDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { employeeId } = useParams<{ employeeId: string }>();

  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const navigate = useNavigate();
  const handleClickInfo = () =>{
    navigate(`/admin/employee/edit-employee/${employeeId}`)
  }
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        if (employeeId) {
          const response = await dispatch(handleGetEmployee(employeeId)).unwrap();
          setEmployee(response);
        }
      } catch (e) {
        handleApiError(e);
      }
    };

    fetchEmployee();
  }, [dispatch, employeeId]);
  
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">{t('Employee Details')}</Typography>
        <Button variant="contained" color="primary" onClick={handleClickInfo}>
          {t('Edit Info')}
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
                padding: '30px 40px 30px 40px',
              borderRadius: 2,
              boxShadow: 1,
              bgcolor: 'background.paper',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', pb: '20px ', mb: '30px', borderBottom: '1px solid #EEEEEE' }}>
              <Avatar
                src={employee?.profile?.avatar?.url}
                alt={employee?.fullName}
                sx={{ width: 70, height: 70, mr: 2 }}
              />
              <Box>
                <Typography variant="h6">{employee?.profile?.fullName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {employee?.role}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={1} sx={{ rowGap: '25px' }}>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.emailIcon} topText={"Email"} bottomText={employee?.email} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.star} topText={t('Stars')} bottomText={employee?.profile?.stars} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.tips} topText={t('Tips')} bottomText={employee?.profile?.tips} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.emailIcon} topText={t('Phone')} bottomText={employee?.profile?.phoneNumber} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.production} topText={t('Production')} bottomText={employee?.profile?.production} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.bonuses} topText={t('Bonuses')} bottomText={employee?.profile?.bonuses} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.address} topText={"Address"} bottomText={employee?.profile?.address} />
              </Grid>
              <Grid xs={4}>
                <InfoCard icon={images.userDetail.sale} topText={"Sales"} bottomText={employee?.profile?.sales} />
              </Grid>
              <Grid xs={4}>
                {/* Add any additional InfoCard or leave empty */}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              padding: '30px 40px 30px 40px',
              borderRadius: 2,
              boxShadow: 1,
              bgcolor: 'background.paper',
            }}
          >
            <Typography variant="h6" className='mb-[30px]'>{t('Job Details')}</Typography>
            <Grid container spacing={2} sx={{ rowGap: '25px' }}>
              {/* First Column: 4 Rows */}
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={2} sx={{ rowGap: '25px' }}>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.department}
                      topText={t('Department')}
                      bottomText={employee?.profile?.department}
                    />
                  </Grid>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.role}
                      topText={t('Role')}
                      bottomText={employee?.role}
                    />
                  </Grid>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.dateJoin}
                      topText={t('Date Joining')}
                      bottomText={employee?.profile?.joinDate}
                    />
                  </Grid>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.jobCompleted}
                      topText={t('Jobs Completed')}
                      bottomText={employee?.profile?.jobsCompleted}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Second Column: 2 Rows */}
              <Grid item xs={12} md={6} >
                <Grid container direction="column" spacing={2} sx={{ rowGap: '25px' }}>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.hoursWorked}
                      topText={t('Hours Worked')}
                      bottomText={employee?.profile?.hoursWorked}
                    />
                  </Grid>
                  <Grid>
                    <InfoCard
                      icon={images.userDetail.productionValue}
                      topText={t('Production Value')}
                      bottomText={`$${employee?.profile?.productionValue}`}
                    />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              padding: '30px 40px 30px 40px',
              borderRadius: 2,
              boxShadow: 1,
              bgcolor: 'background.paper',
              mt: 2,
            }}
          >
            <Typography variant="h6" className='mb-[30px]'>{t('Emergency Contact')}</Typography>
            <Grid container spacing={2} sx={{ rowGap: '25px' }} >
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.father}
                  topText={t('Father Name')}
                  bottomText={employee?.profile?.emergencyContact?.fatherName}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.likes}
                  topText={t('Likes')}
                  bottomText={employee?.profile?.emergencyContact?.likes}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.religion}
                  topText={t('Religion')}
                  bottomText={employee?.profile?.emergencyContact?.religion}
                />
              </Grid>
            
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.father}
                  topText={t('Mother Name')}
                  bottomText={employee?.profile?.emergencyContact?.motherName}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.dislikes}
                  topText={t('Dislikes')}
                  bottomText={employee?.profile?.emergencyContact?.dislikes}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.blood}
                  topText={t('Blood Group')}
                  bottomText={employee?.profile?.emergencyContact?.bloodGroup}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.dateJoin}
                  topText={t('Date of Birth')}
                  bottomText={employee?.profile?.emergencyContact?.dateOfBirth}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.city}
                  topText={t('City')}
                  bottomText={employee?.profile?.emergencyContact?.city}
                />
              </Grid>
              <Grid xs={4}>
                </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.hobbies}
                  topText={t('Hobbies')}
                  bottomText={employee?.profile?.emergencyContact?.hobbies}
                />
              </Grid>
              <Grid xs={4}>
                <InfoCard
                  icon={images.userDetail.state}
                  topText={t('State')}
                  bottomText={employee?.profile?.emergencyContact?.state}
                />
              </Grid>
           
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeDetail;